import { PropsWithChildren } from "react";
import { Modal, ModalProps, Space } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

export const DeleteModal = ({
  children,
  title,
  okButtonProps,
  ...props
}: PropsWithChildren<ModalProps>) => {
  return (
    <Modal
      open={true}
      title={
        <Space>
          <ExclamationCircleFilled
            style={{ fontSize: "1.5rem", color: "red" }}
          />
          {title}
        </Space>
      }
      okButtonProps={{ danger: true, ...okButtonProps }}
      okText="Sterge"
      cancelText="Inchide"
      styles={{ body: { margin: "1rem 0" } }}
      {...props}
    >
      {children}
    </Modal>
  );
};

export enum RouteCategories {
  USERS = "users",
  LISTINGS = "listings",
  TRANSACTIONS = "transactions",
  REPORTS = "reports",
  ADMINS = "admins",
  ROLES = "roles",
  CATEGORIES = 'categories',
  SERVICES = 'services',
  TERMS = 'terms',
  POLICY = 'policy'
}

import { Flex, Layout, Tabs, Typography } from "antd";
import { Link } from "react-router-dom";
import { Notifications } from "components/Notifications";
import { getRouteByName } from "libs";

interface NotificationsPageInterface {
  width?: number | string;
}

export const NotificationsPage = ({ width }: NotificationsPageInterface) => {
  return (
    <Layout.Content>
      <Flex justify="space-between" align="center">
        <Typography.Title level={3} style={{ margin: "0 0 8px" }}>
          Notificari
        </Typography.Title>
        {width && (
          <Link to={getRouteByName("NotificationsPage")}>Toata pagina</Link>
        )}
      </Flex>

      <Tabs
        items={[
          {
            label: "Necitite",
            key: "unread",
            children: <Notifications unread width={width || "100%"} />,
          },
          {
            label: "Toate",
            key: "all",
            children: <Notifications width={width || "100%"} />,
          },
        ]}
        defaultValue="unread"
      />
    </Layout.Content>
  );
};

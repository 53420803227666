import ReactQuill, { ReactQuillProps } from "react-quill";

export const HtmlEditor = ({ ...props }: ReactQuillProps) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  console.log(props, 'props')

  return (
    <ReactQuill
      theme="snow"
      modules={modules}
      formats={formats}
      {...props}
    />
  );
};

import { createSlice } from "@reduxjs/toolkit";

interface AppSettingsState {
  showSideBar: boolean;
  notifications: {
    allPage: number;
    unreadPage: number;
  };
}

const initialState: AppSettingsState = {
  showSideBar: false,
  notifications: {
    allPage: 1,
    unreadPage: 1,
  },
};

export const appSettingsSlice = createSlice({
  name: "app-settings",
  initialState,
  reducers: {
    changeShowSideBar: (state) => ({
      ...state,
      showSideBar: !state.showSideBar,
    }),
    setShowSideBar: (state, { payload }) => ({
      ...state,
      showSideBar: payload,
    }),
    setAllNotificationsPage: (state) => ({
      ...state,
      notifications: {
        ...state.notifications,
        allPage: state.notifications.allPage + 1,
      },
    }),
    setUnreadNotificationsPage: (state) => ({
      ...state,
      notifications: {
        ...state.notifications,
        unreadPage: state.notifications.unreadPage + 1,
      },
    }),
  },
});

export const {
  changeShowSideBar,
  setShowSideBar,
  setAllNotificationsPage,
  setUnreadNotificationsPage,
} = appSettingsSlice.actions;

export default appSettingsSlice.reducer;

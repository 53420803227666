import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CheckCircleOutlined, EyeOutlined } from "@ant-design/icons";
import {
  App,
  DatePicker,
  Flex,
  Input,
  Layout,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/lib/table";
import { useGetReportsQuery, useUpdateReportMutation } from "api/reports";
import { useCustomToken, useFilters, useHash, useQueryParams } from "hooks";
import { format, getRouteByName, palette } from "libs";
import {
  ReportInterface,
  ReportsStatuses,
  useGetUsersInfiniteQuery,
} from "api";
import { getErrorMessage, SmartSelectPagination } from "components";
import { enumKeys } from "types/object";
import { useAppSelector } from "store/hooks";

interface ReportsFiltersInterface {
  "filters[userId][$eq]"?: number[];
  "filters[postId][$eq]"?: number[];
  "filters[reporterId][$eq]"?: number[];
  "filters[status][$eq]"?: number;
  "filters[createdAt][$gt]"?: string;
  "filters[createdAt][$lte]"?: string;
  "filters[id][$eq]"?: string;
  page: number;
}

interface ReportsPageInterface {
  filterKey?: string;
}

export const ReportsPage = ({ filterKey }: ReportsPageInterface) => {
  const { userId, listingId } = useParams();
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const queryParams = useQueryParams();
  const { token } = useCustomToken();

  const [updatingReport, setUpdatingReport] = useState<ReportInterface>();

  const { filters, onFiltersChange } = useFilters<ReportsFiltersInterface>(
    {
      "filters[userId][$eq]": undefined,
      "filters[postId][$eq]": undefined,
      "filters[reporterId][$eq]": undefined,
      "filters[status][$eq]": undefined,
      "filters[createdAt][$gt]": undefined,
      "filters[createdAt][$lte]": undefined,
      "filters[id][$eq]": undefined,
      page: 1,
    },
    { keyword: filterKey || "reports" }
  );

  const { isReportsRead, isUsersRead, isReportsEdit, isPostsRead, isAll } =
    useAppSelector((state) => state.loggedAdmin.parsedPermissions);

  const { data, isLoading, isFetching } = useGetReportsQuery(filters);

  const [
    updateReportMutation,
    { isLoading: isUpdateReportLoading, isSuccess, error },
  ] = useUpdateReportMutation();

  useEffect(() => {
    if (!isReportsRead && !isAll) {
      navigate(getRouteByName("NotFoundPage"));
    }
  }, [isReportsRead, isAll]);

  useHash("reports");

  useEffect(() => {
    if (userId) {
      onFiltersChange({ "filters[userId][$eq]": [Number(userId)] });
    }
  }, [userId]);

  useEffect(() => {
    if (listingId) {
      onFiltersChange({ "filters[postId][$eq]": [Number(listingId)] });
    }
  }, [listingId]);

  useEffect(() => {
    if (updatingReport) {
      updateReportMutation({ id: updatingReport.id, status: 2 });
    }
  }, [updatingReport]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: "Reportul a fost rezolvat" });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      notification.error({ message: getErrorMessage(error) });
    }
  }, [error]);

  useEffect(() => {
    if (
      queryParams["filters[id][$eq]"] &&
      typeof queryParams["filters[id][$eq]"] === "string"
    ) {
      onFiltersChange({
        "filters[id][$eq]": queryParams["filters[id][$eq]"],
      });
    }
  }, [queryParams["filters[id][$eq]"]]);

  const usersColumns = useMemo<ColumnsType<ReportInterface>>(
    () => [
      {
        title: "Anunt",
        dataIndex: "post",
        render: (post) =>
          isAll || isPostsRead ? (
            <Link
              to={{
                pathname: getRouteByName("ListingPage", { listingId: post.id }),
              }}
            >
              {post.title}
            </Link>
          ) : (
            post.title
          ),
      },
      {
        title: "Utilizator",
        dataIndex: "user",
        render: (user) =>
          isAll || isUsersRead ? (
            <Link to={getRouteByName("UserPage", { userId: user.id })}>
              {user.firstName} {user.lastName}
            </Link>
          ) : (
            <>
              {user.firstName} {user.lastName}
            </>
          ),
      },
      {
        title: "Reporter",
        dataIndex: "reporter",
        render: (reporter) =>
          isAll || isUsersRead ? (
            <Link to={getRouteByName("UserPage", { userId: reporter.id })}>
              {reporter.firstName} {reporter.lastName}
            </Link>
          ) : (
            <>
              {reporter.firstName} {reporter.lastName}
            </>
          ),
      },
      {
        title: "Spam",
        dataIndex: "hasSpam",
        render: (hasSpam) => (hasSpam ? "Da" : "Nu"),
      },
      {
        title: "Racism",
        dataIndex: "hasRacism",
        render: (hasRacism) => (hasRacism ? "Da" : "Nu"),
      },
      {
        title: "Pornografie",
        dataIndex: "hasPorn",
        render: (hasPorn) => (hasPorn ? "Da" : "Nu"),
      },
      {
        title: "Cuvinde necenzurte",
        dataIndex: "hasBadWords",
        render: (hasBadWords) => (hasBadWords ? "Da" : "Nu"),
      },
      {
        title: "Descriere",
        dataIndex: "description",
      },
      {
        title: "Statut",
        dataIndex: "status",
        render: (status) => t(ReportsStatuses[status]),
      },
      {
        title: "Data",
        dataIndex: "createdAt",
        width: 200,
        render: (createAt) => (
          <Space>
            <Typography.Text>{format(createAt)}</Typography.Text>
            <Typography.Text>{format(createAt, "HH:mm A")}</Typography.Text>
          </Space>
        ),
      },
      {
        fixed: "right",
        width: 75,
        align: "end",
        render: (_, report) => (
          <>
            {!filterKey && (
              <Space>
                {(isAll || isReportsEdit) &&
                  report.status === ReportsStatuses.PENDING && (
                    <>
                      {updatingReport?.id === report.id &&
                      isUpdateReportLoading ? (
                        <Spin />
                      ) : (
                        <CheckCircleOutlined
                          style={{
                            fontSize: "1rem",
                            cursor: "pointer",
                            color: token.primaryColor,
                          }}
                          onClick={() => setUpdatingReport(report)}
                        />
                      )}
                    </>
                  )}

                {(isAll || isPostsRead) && (
                  <Link
                    to={{
                      pathname: getRouteByName("ListingPage", {
                        listingId: report.id,
                      }),
                      hash: "#reports",
                    }}
                  >
                    <EyeOutlined
                      style={{ fontSize: "1rem", cursor: "pointer" }}
                    />
                  </Link>
                )}
              </Space>
            )}
          </>
        ),
      },
    ],
    [token, filterKey, updatingReport, isPostsRead, isReportsEdit, isAll]
  );

  return (
    <>
      <Flex justify="space-between" style={{ marginBottom: "1rem" }}>
        <Space wrap>
          <Input.Search
            placeholder="Cauta id"
            value={filters["filters[id][$eq]"]}
            onChange={(val) =>
              onFiltersChange({ "filters[id][$eq]": val.target.value })
            }
            allowClear
          />

          {!userId && (
            <SmartSelectPagination
              query={useGetUsersInfiniteQuery}
              style={{ minWidth: 200 }}
              placeholder="Alege utilizator"
              value={filters["filters[userId][$eq]"]}
              onChange={(val) =>
                onFiltersChange({ "filters[userId][$eq]": val })
              }
              filterOption={false}
              allowClear
              // showSearch
            />
          )}

          <SmartSelectPagination
            query={useGetUsersInfiniteQuery}
            style={{ minWidth: 200 }}
            placeholder="Alege reporter"
            value={filters["filters[reporterId][$eq]"]}
            onChange={(val) =>
              onFiltersChange({ "filters[reporterId][$eq]": val })
            }
            filterOption={false}
            allowClear
            // showSearch
          />

          <Select
            placeholder="Statut"
            style={{ width: 125 }}
            value={filters["filters[status][$eq]"]}
            onChange={(val) => onFiltersChange({ "filters[status][$eq]": val })}
            allowClear
          >
            {enumKeys(ReportsStatuses).map((i) => (
              <Select.Option key={i} value={ReportsStatuses[i]}>
                {t(i)}
              </Select.Option>
            ))}
          </Select>

          <DatePicker.RangePicker
            defaultValue={
              filters["filters[createdAt][$gt]"] !== undefined
                ? [
                    dayjs(filters["filters[createdAt][$gt]"], "YYYY-MM-DD"),
                    dayjs(filters["filters[createdAt][$lte]"], "YYYY-MM-DD"),
                  ]
                : undefined
            }
            placeholder={["Creat de la", "Creat pina la"]}
            onChange={(date: any) => {
              onFiltersChange({
                "filters[createdAt][$gt]":
                  date && date[0]
                    ? date[0].format("YYYY-MM-DDT00:00Z")
                    : undefined,
                "filters[createdAt][$lte]":
                  date && date[1]
                    ? date[1].format("YYYY-MM-DDT23:59Z")
                    : undefined,
              });
            }}
          />
        </Space>
      </Flex>
      <Layout style={{ backgroundColor: palette.white }}>
        <Table
          columns={usersColumns}
          loading={isLoading || isFetching}
          dataSource={data?.data}
          rowKey="id"
          pagination={{
            total: data?.pagination.total,
            current: data?.pagination.currentPage,
            pageSize: data?.pagination.itemsPerPage,
            onChange: (page) => onFiltersChange({ page }),
            showSizeChanger: false,
          }}
          scroll={{ x: 1400 }}
        />
      </Layout>
    </>
  );
};

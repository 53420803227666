import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Image, Upload, UploadProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";
import { getBase64 } from "../libs";
import { FileType } from "../pages/admin/UserPage/UserInfo";

interface UploadGalleryInterface extends UploadProps {
  onImagesChange?: Dispatch<SetStateAction<string[]>>;
}

export const UploadGallery = ({
  customRequest,
  onImagesChange = () => "",
  ...props
}: UploadGalleryInterface) => {
  return (
    <Image.PreviewGroup>
      <Upload
        listType="picture-card"
        itemRender={(_, file) => (
          <CustomImage file={file} onImagesChange={onImagesChange} />
        )}
        customRequest={customRequest}
        multiple
        {...props}
      >
        <PlusOutlined style={{ fontSize: 40 }} />
      </Upload>
    </Image.PreviewGroup>
  );
};

const CustomImage = ({
  file,
  onImagesChange,
}: {
  file: UploadFile;
  onImagesChange: Dispatch<SetStateAction<string[]>>;
}) => {
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const updateLocalImageUrl = () => {
      if (file.url) {
        setUrl(file.url);
        return;
      }

      getBase64(file.originFileObj as FileType, (url) => {
        setUrl(url);
        onImagesChange((prevState) => [...prevState, url]);
      });
    };

    updateLocalImageUrl();
  }, [file]);

  return (
    <Image
      src={url}
      style={{
        objectFit: "cover",
        width: "100%",
        height: "100%",
        borderRadius: 8,
      }}
      wrapperStyle={{ width: 100, height: 100 }}
    />
  );
};

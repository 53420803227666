import { useParams } from "react-router-dom";
import { useGetListingCommentsQuery } from "../api";
import { List, Spin } from "antd";
import { useHash } from "../hooks";
import { ListingComment } from "./ListingComment";

export const ListingComments = () => {
  const { listingId } = useParams();

  useHash("comments");

  const { data, isLoading } = useGetListingCommentsQuery({
    id: Number(listingId),
  });

  console.log(data, 'data')

  return (
    <Spin spinning={isLoading}>
      <List
        itemLayout="horizontal"
        dataSource={data?.data.filter((i) => !i.parentId)}
        renderItem={(i) => (
          <ListingComment
            comment={i}
            replies={data?.data.filter((item) => item.parentId === i.id)}
          />
        )}
      />
    </Spin>
  );
};

import { useEffect } from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { Badge, Flex, Grid, Layout, Menu } from "antd";
import { flatAdminRoutes, flatSuperAdminRouter } from "libs";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { renderProperty } from "libs/renderHelpers";
import { setShowSideBar } from "../store/appSettingsSlice";
import { useGetAdminStatsQuery } from "../api";
import { selectSideBarData } from "../store/selectors";

import { ColorLogo } from "assets/svg";

interface SideBarInterface {
  routes?: typeof flatAdminRoutes | typeof flatSuperAdminRouter;
}

export const SideBar = ({ routes = flatAdminRoutes }: SideBarInterface) => {
  const location = useLocation();
  const navigate = useNavigate();

  const matches = matchRoutes(routes, location.pathname);

  const { showSideBar, parsedPermissions } = useAppSelector(selectSideBarData);
  const dispatch = useAppDispatch();

  const breakpoints = Grid.useBreakpoint();

  const { data } = useGetAdminStatsQuery(undefined, {
    skip: !(
      parsedPermissions.isAll ||
      parsedPermissions.isPostsRead ||
      parsedPermissions.isReportsRead
    ),
  });

  useEffect(() => {
    dispatch(setShowSideBar(Boolean(breakpoints.xs)));
  }, [breakpoints.xs]);

  const withBadges = ["ListingsPage", "ReportsPage"];

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsedWidth={breakpoints.xs ? 0 : 80}
      collapsed={showSideBar}
      style={{ position: "relative", backgroundColor: "black", width: 0 }}
      breakpoint="sm"
    >
      <Flex justify="center" align="center" style={{ margin: "1rem 0" }}>
        <ColorLogo style={{ width: "70%" }} />
      </Flex>
      <Menu
        mode="inline"
        theme="dark"
        style={{ backgroundColor: "black" }}
        selectedKeys={matches?.map((i) => i.route.category || "")}
        items={routes
          .filter(
            (i) =>
              "label" in i &&
              (!i.permission ||
                parsedPermissions.isAll ||
                parsedPermissions[i.permission])
          )
          .map((i) => ({
            label: i.label,
            key: i.category || "",
            icon: (
              <span>
                {renderProperty(i.icon)}

                {i.name && withBadges.includes(i.name) && (
                  <Badge
                    count={data && data[i.name as keyof typeof data]}
                    size="small"
                    offset={[-15, -17]}
                    style={{
                      boxShadow: "none",
                      color: "black",
                      backgroundColor: "white",
                      width: "fit-content",
                    }}
                    styles={{ root: { width: 0, margin: 0 } }}
                  />
                )}
              </span>
            ),
            onClick: () => navigate(i.path),
          }))}
      />
    </Layout.Sider>
  );
};

import { PropsWithChildren, useEffect, useState } from "react";
import { Flex, Spin } from "antd";
import { useAppDispatch } from "../store/hooks";
import { useGetLoggedAdminQuery } from "../api";
import { setLoggedAdminData } from "../store/loggedAdminSlice";
import { camelize } from "../libs";

interface initializeDefaultAppDataInterface {}

export const DefaultAppDataInit = ({
  children,
}: PropsWithChildren<initializeDefaultAppDataInterface>) => {
  const [isAppReady, setIsAppReady] = useState(false);

  const dispatch = useAppDispatch();

  const { data, isSuccess } = useGetLoggedAdminQuery(undefined);

  useEffect(() => {
    if (data) {
      dispatch(
        setLoggedAdminData({
          data: data,
          parsedPermissions: data.permissions.reduce(
            (acc, i) => ({ ...acc, [`is${camelize(i.name)}`]: true }),
            {}
          ),
        })
      );
    }
  }, [data]);

  useEffect(() => {
    const checkIfIsLoggedAdminData = () => {
      if (isSuccess) {
        setIsAppReady(true);
      }
    };

    checkIfIsLoggedAdminData();
  }, [isSuccess]);

  if (isAppReady) {
    return (
      <div style={{ minHeight: "100vh", height: "100vh" }}>{children}</div>
    );
  }

  return (
    <Flex style={{ minHeight: "100vh" }} justify="center" align="center">
      <Spin spinning size="large" />
    </Flex>
  );
};

import { useState } from "react";
import { CommentsReplies } from "./CommentsReplies";
import { Avatar, List, Space, theme } from "antd";
import { Trans } from "react-i18next";
import { getDiffFromToday } from "../libs";
import { Comment } from "../api";

interface ListingCommentInterface {
  comment: Comment;
  replies?: Comment[];
}

export const ListingComment = ({
  comment,
  replies,
}: ListingCommentInterface) => {
  const { token } = theme.useToken();

  const [isShowReplies, setIsShowReplies] = useState(false);

  return (
    <List.Item
      style={{
        flexDirection: "column",
        alignItems: "inherit",
      }}
      extra={
        <CommentsReplies
          replies={replies}
          onShowRepliesChange={setIsShowReplies}
        />
      }
    >
      <List.Item.Meta
        avatar={
          <Avatar
            src={comment.user.profilePicture}
            style={{ position: "relative", zIndex: 1 }}
          />
        }
        title={
          <Space>
            <span>
              {comment.user.firstName} {comment.user.lastName}
            </span>

            <span style={{ color: "grey", fontWeight: 300 }}>
              <Trans {...getDiffFromToday(comment.createdAt)}></Trans>
            </span>
          </Space>
        }
        description={
          <div style={{ position: "relative", zIndex: 0 }}>
            {isShowReplies && (
              <div
                style={{
                  width: 1,
                  backgroundColor: token.colorPrimary,
                  position: "absolute",
                  height: "105%",
                  top: "0.5rem",
                  left: "-2%",
                }}
              />
            )}

            {comment.comment}
          </div>
        }
        style={{ position: "relative" }}
      />
    </List.Item>
  );
};

import { useEffect } from "react";
import { App, Form, Input, Modal, Select } from "antd";
import { useCreateServiceMutation, useGetCategoriesQuery } from "api";
import { formErrorHandler } from "libs";

interface ServiceFormInterface {
  onCancel: () => void;
}

export const ServiceForm = ({ onCancel }: ServiceFormInterface) => {
  const [form] = Form.useForm();
  const { notification } = App.useApp();

  const { data, isLoading: isCategoriesLoading } = useGetCategoriesQuery({});

  const [createServiceMutation, { isLoading, isSuccess, error }] =
    useCreateServiceMutation();

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: `Serviciul a fost creat cu success`,
      });
      onCancel();
    }
  }, [isSuccess, notification, onCancel]);

  useEffect(() => {
    if (error) {
      notification.error({ message: "Serviciul nu a putut fi creat" });
      form.setFields(formErrorHandler((error as any).data));
    }
  }, [error, notification, form]);

  return (
    <Modal
      title="Adauga rol"
      okText="Creeaza"
      onOk={() => createServiceMutation(form.getFieldsValue())}
      okButtonProps={{ loading: isLoading }}
      onCancel={onCancel}
      cancelText="Inchide"
      open
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ width: "80%" }}
      >
        <Form.Item label="Nume" name="name">
          <Input placeholder="Numele" />
        </Form.Item>

        <Form.Item label="Categori" name="category">
          <Select
            loading={isCategoriesLoading}
            className="capitalize"
            placeholder="Alege categoria"
          >
            {data?.map((i) => (
              <Select.Option key={i.id} value={i.id} className="capitalize">
                {i.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  adminsApi,
  ListingsStatuses,
  ListingsTypes,
  useGetCountiesQuery,
  useGetCountriesQuery,
  useGetListingQuery,
  useUpdateListingsMutation,
  useUpdateListingStatusMutation,
} from "api";
import {
  App,
  Button,
  Card,
  DatePicker,
  Form,
  Grid,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { UploadGallery } from "components";
import { SmartSelect } from "components/SmartSelect";
import { dateTimeFormat, format, formErrorHandler, today } from "libs";
import { enumKeys } from "types/object";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Gallery } from "../../../components/Gallery";

export const ListingInfo = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { listingId } = useParams();

  const { notification } = App.useApp();

  const [isEditMode, setIsEditMode] = useState(false);
  const [showAdditionalAgreeModal, setShowAdditionalAgreeModal] =
    useState<ListingsStatuses>();
  const [reason, setReason] = useState<string>();
  const [listingMedia, setListingMedia] = useState<string[]>([]);
  const [changedValues, setChangedValues] = useState<any>({});

  const dispatch = useAppDispatch();

  const { TextArea } = Input;

  const { isPostsRead, isPostsDelete, isAll } = useAppSelector(
    (state) => state.loggedAdmin.parsedPermissions
  );

  const { data, isLoading } = useGetListingQuery({ id: Number(listingId) });

  const [
    updateListingMutation,
    { isLoading: isUpdateLoading, isSuccess, error: updateError },
  ] = useUpdateListingsMutation();

  const [
    updateListingStatusMutation,
    {
      isLoading: isUpdateListingStatusLoading,
      isSuccess: isUpdateListingStatusSuccess,
      isError: isUpdateListingStatusError,
    },
  ] = useUpdateListingStatusMutation();

  const breakpoints = Grid.useBreakpoint();

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      dates: data?.dates.map((i) => dayjs(i)),
      country: data?.country.id,
      county: data?.county.id,
    });
  }, [data, form]);

  useEffect(() => {
    if (isSuccess || isUpdateListingStatusSuccess) {
      dispatch(adminsApi.util.invalidateTags(["AdminsStats"]));
      notification.success({
        message: "Anuntul a fost modificat cu success",
      });
      setShowAdditionalAgreeModal(undefined);
    }
  }, [isSuccess, isUpdateListingStatusSuccess, notification, dispatch]);

  useEffect(() => {
    if (updateError) {
      notification.error({
        message: "Anuntul nu poate fi modificat",
      });
      form.setFields(formErrorHandler((updateError as any).data));
      setShowAdditionalAgreeModal(undefined);
    }
  }, [updateError, notification, form]);

  useEffect(() => {
    if (isUpdateListingStatusError) {
      notification.error({
        message: "Anuntul nu poate fi modificat",
      });
    }
  }, [isUpdateListingStatusError, notification]);

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const getBlocksWidth = () => {
    if (!breakpoints.md) {
      return "100%";
    }

    if (!breakpoints.lg) {
      return "49%";
    }

    return "100%";
  };

  const onFormSubmit = () => {
    updateListingMutation({
      id: data?.id,
      ...changedValues,
      mediaFiles: [
        ...(data?.mediaFiles.map((i) => i.id) || []),
        ...listingMedia,
      ],
      dates: changedValues.dates?.map((i: string) => format(i, "YYYY-MM-DD")),
    });
  };

  const onValuesChange = (changed: any) => {
    setChangedValues((prev: any) => ({
      ...prev,
      ...changed,
    }));
  };

  const validateAndSubmit = () =>
    form
      .validateFields()
      .then(() => onFormSubmit())
      .catch((e) => console.log(e, "submit error"));
  return (
    <>
      <Form
        form={form}
        onValuesChange={onValuesChange}
        requiredMark={false}
        className="half-space-form"
        layout="vertical"
      >
        <Spin spinning={isLoading || isUpdateLoading}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Space style={{ justifyContent: "flex-end", width: "100%" }}>
              {(isAll || isPostsRead) && (
                <>
                  {isEditMode && (
                    <>
                      <Button onClick={() => setIsEditMode(false)}>
                        Anuleaza
                      </Button>
                      <Button type="primary" onClick={validateAndSubmit}>
                        Salveaza
                      </Button>
                    </>
                  )}

                  {!isEditMode && (
                    <Button type="primary" onClick={() => setIsEditMode(true)}>
                      Editeaza
                    </Button>
                  )}

                  {[ListingsStatuses.PENDING, ListingsStatuses.BLOCK].includes(
                    data?.status || ListingsStatuses.PENDING
                  ) && (
                    <Button
                      type="primary"
                      onClick={() =>
                        setShowAdditionalAgreeModal(ListingsStatuses.ACTIVE)
                      }
                    >
                      Activeaza
                    </Button>
                  )}
                </>
              )}

              {(isAll || isPostsDelete) &&
                data?.status &&
                [ListingsStatuses.PENDING, ListingsStatuses.ACTIVE].includes(
                  data.status
                ) && (
                  <Button
                    type="primary"
                    onClick={() =>
                      setShowAdditionalAgreeModal(ListingsStatuses.BLOCK)
                    }
                  >
                    {data?.status === ListingsStatuses.PENDING && "Respinge"}
                    {data?.status === ListingsStatuses.ACTIVE && "Deactiveaza"}
                  </Button>
                )}
            </Space>

            <Space
              align="start"
              style={{ width: "100%" }}
              styles={{
                item: {
                  width: getBlocksWidth(),
                },
              }}
              wrap={!breakpoints.lg}
            >
              <Card title="Anunt">
                <Form.Item
                  name="title"
                  label={<Typography.Text strong>Titlu:</Typography.Text>}
                  required
                  rules={[{ required: true, message: <></> }]}
                >
                  {isEditMode ? <Input /> : data?.title}
                </Form.Item>

                <Form.Item
                  name="description"
                  label={<Typography.Text strong>Descriere:</Typography.Text>}
                  required
                  rules={[{ required: true, message: <></> }]}
                >
                  {isEditMode ? <TextArea rows={4} /> : data?.description}
                </Form.Item>

                <Form.Item
                  label={<Typography.Text strong>Utilizator:</Typography.Text>}
                >
                  {data?.user.firstName} {data?.user.lastName}
                </Form.Item>

                <Form.Item
                  name="type"
                  label={<Typography.Text strong>Tip:</Typography.Text>}
                  required
                  rules={[{ required: true, message: <></> }]}
                >
                  {isEditMode ? (
                    <Select
                      style={{ width: 150 }}
                      placeholder="Alege tip"
                      allowClear
                    >
                      {enumKeys(ListingsTypes).map((i) => (
                        <Select.Option key={`list-types-${i}`} value={ListingsTypes[i]}>
                          {i}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : data?.type ? (
                    t(ListingsTypes[data.type])
                  ) : (
                    ""
                  )}
                </Form.Item>

                <Form.Item
                  label={<Typography.Text strong>Statut:</Typography.Text>}
                  required
                >
                  {data?.status ? t(ListingsStatuses[data.status]) : ""}
                </Form.Item>

                <Form.Item
                  name="mediaFiles"
                  label={<Typography.Text strong>Poze:</Typography.Text>}
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  {isEditMode ? (
                    <UploadGallery
                      customRequest={() => null}
                      onImagesChange={setListingMedia}
                    />
                  ) : (
                    <Gallery mediaFiles={data?.mediaFiles} />
                  )}
                </Form.Item>

                <Form.Item
                  name="dates"
                  label={
                    <Typography.Text strong>Data publicarii:</Typography.Text>
                  }
                >
                  {isEditMode ? (
                    <DatePicker multiple minDate={today} />
                  ) : (
                    data?.dates.map((date) => format(date)).join(", ")
                  )}
                </Form.Item>

                <Form.Item
                  label={<Typography.Text strong>Creat la:</Typography.Text>}
                >
                  {format(data?.createdAt, dateTimeFormat)}
                </Form.Item>
              </Card>

              <Card title="Locatie">
                <Form.Item
                  name="country"
                  label={<Typography.Text strong>Country:</Typography.Text>}
                >
                  {isEditMode ? (
                    <SmartSelect
                      query={useGetCountriesQuery}
                      placeholder="Alege tara"
                      allowClear
                    />
                  ) : (
                    data?.country.name
                  )}
                </Form.Item>

                <Form.Item
                  name="county"
                  label={<Typography.Text strong>County:</Typography.Text>}
                >
                  {isEditMode ? (
                    <SmartSelect
                      query={useGetCountiesQuery}
                      placeholder="Alege judetul"
                      allowClear
                    />
                  ) : (
                    data?.county.name
                  )}
                </Form.Item>
              </Card>

              <Card title="Statistica">
                <Form.Item
                  label={<Typography.Text strong>Like-uri:</Typography.Text>}
                >
                  {data?.likes}
                </Form.Item>
                <Form.Item
                  label={<Typography.Text strong>Vizualizari:</Typography.Text>}
                >
                  {data?.views}
                </Form.Item>
                <Form.Item
                  label={<Typography.Text strong>Comentarii:</Typography.Text>}
                >
                  {data?.comments}
                </Form.Item>
              </Card>
            </Space>
          </Space>
        </Spin>
      </Form>

      {showAdditionalAgreeModal && (
        <Modal
          open
          title="Modificarea postarea"
          okText="Modifica"
          cancelText="Inchide"
          styles={{ body: { margin: "2rem 0" } }}
          onCancel={() => setShowAdditionalAgreeModal(undefined)}
          onOk={() =>
            updateListingStatusMutation({
              id: data?.id,
              status: showAdditionalAgreeModal,
              reason,
            })
          }
          okButtonProps={{
            loading: isUpdateListingStatusLoading,
            disabled:
              ListingsStatuses.BLOCK === showAdditionalAgreeModal && !reason,
          }}
        >
          <Typography.Text>
            Esti sigur ca vrei sa schimbi statul postarii in "
            {ListingsStatuses[showAdditionalAgreeModal]}"?
          </Typography.Text>
          {ListingsStatuses.BLOCK === showAdditionalAgreeModal && (
            <Form.Item label="Cauza:" style={{ marginTop: "1rem" }}>
              <Input
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Form.Item>
          )}
        </Modal>
      )}
    </>
  );
};

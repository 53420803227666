import { Button, Flex, theme, Typography } from "antd";
import { useNavigate } from "react-router-dom";

export const NotFoundPage = () => {
  const navigate = useNavigate();

  const { token } = theme.useToken();

  return (
    <Flex
      justify="center"
      align="center"
      style={{ height: "100vh", backgroundColor: token.colorPrimary }}
      vertical
    >
      <Typography.Title level={1} style={{ color: 'white' }}>Acesta pagina nu exista</Typography.Title>

      <Button type="default" onClick={() => navigate(-1)} size="large">
        Mergi inapoi
      </Button>
    </Flex>
  );
};

import { SetStateAction, useEffect, useRef, useState } from "react";
import { load, save } from "react-cookies";
import { getOnlyExistingKeys, withValues } from "libs/object";
import { useQueryParams } from "./useQueryParams";
import { useQueryUpdate } from "./useQueryUpdate";
import { $Object } from "types/object";

export type onFilterChange<T> = (
  newValues: Partial<T>,
  reset?: boolean
) => void;

export type FilterResult<T> = {
  filters: T;
  onFiltersChange: onFilterChange<T>;
};

type Options = {
  keyword: string;
  useQueryParams?: boolean;
  /**
   * Will get parsed from query with JSON.parse
   */
  objectKeys?: string[];
};

const FILTERS = "__filters__";

export const useFilters = <T extends $Object>(
  initialFilters: T,
  options: Options
): FilterResult<T> => {
  const storedFilters = load(FILTERS);

  const localFilters = useRef<$Object>(storedFilters || {});

  const query = useQueryParams();
  const { updateQuery } = useQueryUpdate();

  const currentFilters = useRef<T>(
    getOnlyExistingKeys(
      initialFilters,
      Object.keys(query).length > 0 && !options.useQueryParams
        ? ({ ...initialFilters, ...query } as T)
        : localFilters.current[options.keyword]
        ? localFilters.current[options.keyword]
        : withValues(initialFilters)
    )
  );

  useEffect(() => {
    updateQuery(withValues(currentFilters.current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filters, setFilters] = useState(currentFilters.current);

  const onFiltersChange = (newValues: $Object, reset = false): void => {
    const newFilters = {
      ...(!reset && query),
      ...newValues,
    };

    setFilters(newFilters as unknown as SetStateAction<T>);
    save(
      FILTERS,
      JSON.stringify({
        ...localFilters.current,
        [options.keyword]: newFilters,
      }),
      { path: "/" }
    );

    updateQuery(withValues(newFilters));
  };

  return { filters, onFiltersChange };
};

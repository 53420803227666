import { App, Button, Form, Input, Space, theme, Typography } from "antd";
import { Link } from "react-router-dom";
import { getRouteByName, palette } from "libs";
import { useEffect } from "react";

interface SubmitEmailFormInterface {
  onSubmit: (values: any) => void;
  isLoading: boolean;
  isSuccess: boolean;
  onSuccess: (email: string) => void;
  error: any;
}

export const SubmitEmailForm = ({
  onSubmit,
  isLoading,
  isSuccess,
  onSuccess,
  error,
}: SubmitEmailFormInterface) => {
  const { notification } = App.useApp();
  const { token } = theme.useToken();
  const [form] = Form.useForm();

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: "Un cod de confirmare a fost trimis pe email",
      });
      onSuccess(form.getFieldsValue().email);
    }
  }, [isSuccess, notification, onSuccess, form]);

  useEffect(() => {
    if (error) {
      notification.error({ message: "A aparut o eroare la generarea codului" });
    }
  }, [error, notification]);

  return (
    <Space direction="vertical" style={{ width: "20rem", gap: 0 }}>
      <Typography.Title level={2} style={{ margin: 0 }}>
        Reseteaza parola
      </Typography.Title>
      <Typography.Text style={{ color: token.colorPrimary }}>
        Te rugam introdu un email
      </Typography.Text>

      <Form form={form} style={{ marginTop: "2rem" }} onFinish={onSubmit}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              // eslint-disable-next-line
              pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Emailul introdus nu pare sa existe",
            },
          ]}
        >
          <Input placeholder="Introdu email-ul" type="email" size="large" />
        </Form.Item>

        <Button
          type="primary"
          style={{ width: "100%" }}
          htmlType="submit"
          size="large"
          loading={isLoading}
        >
          Primeste email
        </Button>
      </Form>

      <Space
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "1rem 0 0",
        }}
      >
        <Link to={getRouteByName("LogInPage")}>
          <Typography.Text style={{ color: palette.primaryColor }}>
            Logheaza-te
          </Typography.Text>
        </Link>
      </Space>
    </Space>
  );
};

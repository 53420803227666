import {
  AlignLeftOutlined,
  AlignRightOutlined,
  BarsOutlined,
  ForkOutlined,
  UserOutlined,
  PoundOutlined,
  TagOutlined,
  TagsOutlined,
  SoundOutlined,
} from "@ant-design/icons";
import { ListingsPage } from "pages/admin/ListingsPage";
import { UsersPage } from "pages/admin/UsersPage";
import { RouteCategories } from "./enums";
import { AdminsPage } from "pages/superAdmin/AdminsPage";
import { LogInPage } from "pages/common/LogInPage";
import { ResetPasswordPage } from "pages/common/ResetPasswordPage";
import { RolesPage } from "../pages/superAdmin/RolesPage";
import { UserPage } from "../pages/admin/UserPage/UserPage";
import { TransactionsPage } from "../pages/admin/TransactionsPage";
import { ReportsPage } from "../pages/admin/ReportsPage";
import { ListingPage } from "../pages/admin/ListingPage/ListingPage";
import { IndexPage } from "../pages/admin/IndexPage";
import { TermsPage } from "../pages/admin/TermsPage";
import { CategoriesPage } from "../pages/admin/CategoriesPage/CategoriesPage";
import { ServicesPage } from "../pages/admin/ServicesPage/ServicesPage";
import { PolicyPage } from "../pages/admin/PolicyPage";
import { NotFoundPage } from "../pages/common/NotFoundPage";
import { NotificationsPage } from "../pages/admin/NotificationsPage";

export const publicRoutes = [
  {
    name: "IndexPage",
    path: "/",
    element: <IndexPage />,
  },
  {
    name: "LogInPage",
    path: "/login",
    element: <LogInPage />,
  },
  {
    name: "ResetPasswordPage",
    path: "/reset-password",
    element: <ResetPasswordPage />,
  },
  {
    name: "NotFoundPage",
    path: "/404",
    element: <NotFoundPage />,
  },
];

export const adminRoutes = [
  {
    name: "UsersPage",
    label: "Utilizatori",
    category: RouteCategories.USERS,
    path: "/users",
    element: <UsersPage />,
    icon: UserOutlined,
    permission: "isUsersRead",
  },
  {
    name: "UserPage",
    category: RouteCategories.USERS,
    path: "/users/:userId",
    element: <UserPage />,
    permission: "isUsersRead",
  },
  {
    name: "ListingsPage",
    label: "Anunturi",
    category: RouteCategories.LISTINGS,
    path: "/listings",
    element: <ListingsPage />,
    icon: BarsOutlined,
    permission: "isPostsRead",
  },
  {
    name: "ListingPage",
    category: RouteCategories.LISTINGS,
    path: "/listing/:listingId",
    element: <ListingPage />,
    permission: "isPostsRead",
  },
  {
    name: "TransactionsPage",
    label: "Tranzactii",
    category: RouteCategories.TRANSACTIONS,
    path: "/transactions",
    element: <TransactionsPage />,
    icon: PoundOutlined,
    permission: "isTransactionsRead",
  },
  {
    name: "ReportsPage",
    label: "Raportari",
    category: RouteCategories.REPORTS,
    path: "/reports",
    element: <ReportsPage />,
    icon: SoundOutlined,
    permission: "isReportsRead",
  },
  {
    label: "Categorii",
    category: RouteCategories.CATEGORIES,
    path: "/settings/categories",
    element: <CategoriesPage />,
    icon: TagOutlined,
    permission: "isCategoriesRead",
  },
  {
    label: "Servicii",
    category: RouteCategories.SERVICES,
    path: "/settings/services",
    element: <ServicesPage />,
    icon: TagsOutlined,
    permission: "isServicesRead",
  },
  {
    label: "Termeni",
    category: RouteCategories.TERMS,
    path: "/settings/terms",
    element: <TermsPage />,
    icon: AlignLeftOutlined,
  },
  {
    label: "Policy",
    category: RouteCategories.POLICY,
    path: "/settings/policy",
    element: <PolicyPage />,
    icon: AlignRightOutlined,
  },
  {
    name: "NotificationsPage",
    path: "/notifications",
    element: <NotificationsPage />,
  }
];

export const superAdminRoutes = [
  {
    name: "AdminsPage",
    label: "Administratori",
    category: RouteCategories.ADMINS,
    path: "/admins",
    element: <AdminsPage />,
    icon: UserOutlined,
    permission: "isUsersRead",
  },
  {
    name: "RolesPage",
    label: "Roluri",
    category: RouteCategories.ROLES,
    path: "/roles",
    element: <RolesPage />,
    icon: ForkOutlined,
    permission: "isUsersRead",
  },
];

export const routes = [...publicRoutes, ...adminRoutes, ...superAdminRoutes];

export const flatAdminRoutes = adminRoutes.filter((i) => "category" in i);
export const flatSuperAdminRouter = superAdminRoutes.filter(
  (i) => "category" in i
);

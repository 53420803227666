import React, { PropsWithChildren, ReactNode } from "react";
import {
  Avatar,
  Badge,
  Button,
  Dropdown,
  Flex,
  Layout,
  Popover,
  Space,
  theme,
} from "antd";
import {
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { remove } from "react-cookies";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { getRightInterval } from "./utils";
import { SideBar as MainSideBar } from "./SideBar";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { changeShowSideBar } from "store/appSettingsSlice";
import { DefaultAppDataInit } from "./DefaultAppDataInit";
import { getRouteByName } from "libs";
import { NotificationsProvider } from "./NotificationsProvider";
import { useGetUnreadNotificationsQuery } from "api";
import { NotificationsPage } from "pages/admin/NotificationsPage";

import { PoweredByAsTech } from "assets/png";

interface MainLayoutInterface {
  SideBar?: ReactNode;
  isSuperAdmin?: boolean;
}

export const MainLayout = ({
  children,
  SideBar = <MainSideBar />,
  isSuperAdmin = false,
}: PropsWithChildren<MainLayoutInterface>) => {
  const navigate = useNavigate();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const dispatch = useAppDispatch();
  const { showSideBar } = useAppSelector((state) => state.appSettings);

  const { data } = useGetUnreadNotificationsQuery({
    page: 1,
  });

  return (
    <NotificationsProvider>
      <DefaultAppDataInit>
        <Layout style={{ minHeight: "100vh" }} hasSider>
          <Link to="https://astechsoft.com" target="_blank">
            <img
              src={PoweredByAsTech}
              alt="Power by"
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "10rem",
                height: "auto",
                zIndex: 1,
              }}
            />
          </Link>

          {SideBar}

          <Layout>
            <Layout.Header style={{ padding: 0, background: colorBgContainer }}>
              <Flex justify="space-between" align="center">
                <Button
                  type="text"
                  icon={
                    showSideBar ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                  }
                  onClick={() => dispatch(changeShowSideBar())}
                  style={{
                    fontSize: "16px",
                    width: 64,
                    height: 64,
                    borderRadius: 0,
                  }}
                />

                <Space size="large">
                  {!isSuperAdmin && (
                    <Popover
                      content={<NotificationsPage width={350} />}
                      placement="bottomLeft"
                      arrow
                    >
                      <Badge
                        count={data?.pagination.total}
                        size="small"
                        style={{ cursor: "pointer" }}
                      >
                        <BellOutlined
                          style={{ fontSize: "1rem", cursor: "pointer" }}
                        />
                      </Badge>
                    </Popover>
                  )}

                  <Dropdown
                    menu={{
                      items: [
                        {
                          label: "Log out",
                          key: "logOut",
                          onClick: () => {
                            remove("access-token");
                            navigate(getRouteByName("LogInPage"));
                          },
                        },
                      ],
                    }}
                    trigger={["click"]}
                    arrow
                  >
                    <Avatar
                      size={28}
                      icon={<UserOutlined />}
                      style={{ marginRight: "1rem", cursor: "pointer" }}
                    />
                  </Dropdown>
                </Space>
              </Flex>
            </Layout.Header>
            <Layout.Content
              style={{
                margin: "24px 16px",
                padding: 24,
                minHeight: "cacl(100vh - 4rem - 8rem)",
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              {children}

              <Outlet />
            </Layout.Content>
            <Layout.Footer>
              <Flex
                justify="center"
                align="center"
                style={{ marginBottom: "2rem", textAlign: "center" }}
              >
                Spor App ©{getRightInterval()} | All rights reserved
              </Flex>
            </Layout.Footer>
          </Layout>
        </Layout>
      </DefaultAppDataInit>
    </NotificationsProvider>
  );
};

import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultApiConfig } from "./index";
import { defaultQueryStringify } from "../libs";

export const transactionsApi = createApi({
  ...defaultApiConfig,
  reducerPath: "transactionsApi",
  endpoints: (builder) => ({
    getTransactions: builder.query({
      query: (params) => `admin/transactions?${defaultQueryStringify(params)}`,
    }),
    getUserTransactions: builder.query({
      query: (id) => `admin/user-transactions/${id}`,
    }),
  }),
});

export const { useGetTransactionsQuery } = transactionsApi;

import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultApiConfig, RoleInterface, WithPagination } from "./index";
import { $Object } from "../types/object";
import { default as queryString } from "query-string";

export const rolesApi = createApi({
  ...defaultApiConfig,
  reducerPath: "rolesApi",
  tagTypes: ["Roles"],
  endpoints: (builder) => ({
    getRoles: builder.query<WithPagination<RoleInterface>, $Object>({
      query: (params) =>
        `admin/roles?${queryString.stringify(params, {
          skipEmptyString: true,
          skipNull: true,
          arrayFormat: "comma",
        })}`,
      providesTags: ["Roles"],
    }),
    createRole: builder.mutation({
      query: (body) => ({
        url: `admin/roles`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Roles"],
    }),
    updateRole: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `admin/role/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Roles"],
    }),
    deleteRole: builder.mutation({
      query: (id) => ({ url: `admin/role/${id}`, method: "DELETE" }),
      invalidatesTags: ["Roles"],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = rolesApi;

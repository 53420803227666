import dayjs from "dayjs";

export const today = dayjs();
export const dateFormat = "DD MMM YYYY";
export const dateFormatInput = "dd.MM.yyyy";
export const dateTimeFormat = "DD.MM.YYYY HH:mm";
export const dateTimeFormatInput = "dd.MM.yyyy HH:mm";
export const dateFormatDMCMY = "D MMM, YYYY";
export const dateTimeRowFormat = "DD MMM YYYY, HH:mm A";
export const dateSpecialFormat = "D MMMM YYYY";
export const timeSpecialFormat = "HH:mm A";
export const monthFormat = "MMM";
export const chatDateTime = "HH:mm | DD.MM.YYYY";

export const format = (date?: string, format?: string): string =>
  dayjs(date).format(format || dateFormat);

export const getDiffFromToday = (date: string) => {
  const now = dayjs();
  const timeToDecrease = dayjs(date);

  const year = now.diff(timeToDecrease, "year");
  if (year > 0) {
    return {
      i18nKey: year === 1 ? "aboutAYear" : "years",
      count: year,
    };
  }

  const month = now.diff(timeToDecrease, "month");
  if (month > 0) {
    return {
      i18nKey: month === 1 ? "aboutAMonth" : "months",
      count: month,
    };
  }

  const day = now.diff(timeToDecrease, "day");
  if (day > 0) {
    return {
      i18nKey: day === 1 ? "aDay" : "days",
      count: day,
    };
  }

  const hour = now.diff(timeToDecrease, "hour");
  if (hour > 0) {
    return {
      i18nKey: hour === 1 ? "aboutAnHour" : "hours",
      count: hour,
    };
  }

  const minute = now.diff(timeToDecrease, "minute");
  if (minute > 0) {
    return {
      i18nKey: minute === 1 ? "aboutAMinute" : "minutes",
      count: minute,
    };
  }

  const second = now.diff(timeToDecrease, "second");
  if (second >= 0) {
    return {
      i18nKey: second === 1 ? "aboutASecond" : "seconds_ago",
      count: second,
    };
  }
};

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { App as AntdApp, ConfigProvider } from "antd";
import {
  adminRoutes,
  localThemeConfig,
  publicRoutes,
  superAdminRoutes,
} from "./libs";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { PublicLayout } from "./components/PublicLayout";
import { MainLayout } from "./components/MainLayout";
import { SuperAdminLayout } from "./components/SuperAdminLayout";
import { NotFoundPage } from "./pages/common/NotFoundPage";
import { i18Next } from "./libs/locales/i18n";

i18Next();

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ConfigProvider theme={localThemeConfig}>
          <AntdApp>
            <Routes>
              <Route key="public" Component={PublicLayout}>
                {publicRoutes.map((route) => (
                  <Route
                    key={route.name}
                    path={route.path}
                    element={route.element}
                  />
                ))}
              </Route>

              <Route key="main" Component={MainLayout}>
                {adminRoutes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                ))}
              </Route>

              <Route key="super" Component={SuperAdminLayout}>
                {superAdminRoutes.map((route) => (
                  <Route
                    key={route.name}
                    path={route.path}
                    element={route.element}
                  />
                ))}
              </Route>

              <Route key="NotFoundPage" path="*" Component={NotFoundPage} />
            </Routes>
          </AntdApp>
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;

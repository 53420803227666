import { useEffect } from "react";
import { App, Button, Flex, Form, Grid, Spin, Typography } from "antd";
import { useGetTermsQuery, useUpdateTermsMutation } from "api";
import { HtmlEditor } from "../../components/HtmlEditor";
import { getErrorMessage } from "components";

export const TermsPage = () => {
  const [form] = Form.useForm();
  const { notification } = App.useApp();

  const { data, isLoading } = useGetTermsQuery(undefined);

  const [
    updateTermsMutation,
    { isLoading: isUpdateTermsLoading, error, isSuccess },
  ] = useUpdateTermsMutation();

  const breakpoints = Grid.useBreakpoint();

  useEffect(() => {
    if (data?.data) {
      form.setFieldsValue({ ...data });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      notification.error({ message: getErrorMessage(error) });
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: "Termenii a fost modificata",
      });
    }
  }, [isSuccess]);

  return (
    <Form
      form={form}
      onFinish={(values) => {
        updateTermsMutation(values);
      }}
    >
      <Flex
        justify="space-between"
        style={{ marginBottom: "1rem" }}
        vertical={breakpoints.xs}
        gap={16}
      >
        <Typography.Title level={3} style={{ margin: 0 }}>
          Termeni si conditii
        </Typography.Title>
        <Button type="primary" htmlType="submit" loading={isUpdateTermsLoading}>
          Salveaza
        </Button>
      </Flex>

      <Spin spinning={isLoading}>
        <Form.Item name="data">
          <HtmlEditor placeholder="Introdu continutul aici" />
        </Form.Item>
      </Spin>
    </Form>
  );
};

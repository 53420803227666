import { default as i18n } from "i18next";
import { initReactI18next } from "react-i18next";
import { Locales } from "./locales.types";
import { localesRo } from "./ro";

export const i18next = i18n.createInstance();

export const i18Next = (): any =>
  i18next.use(initReactI18next).init({
    fallbackLng: [Locales.RO],
    lng: Locales.RO,
    resources: {
      ro: {
        translation: localesRo,
      },
    },
  });

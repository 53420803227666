import { palette } from "./palette";
import { ThemeConfig } from "antd/es/config-provider/context";

export const localThemeConfig: ThemeConfig = {
  token: {
    colorPrimary: palette.primaryColor,
    // borderRadius: 99,
  },
  components: {
    Table: {
      headerBg: palette.primaryColor,
      borderColor: palette.primaryColor,
      headerColor: palette.white,
      headerSortHoverBg: `${palette.primaryColor}20`,
    },
    Tag: {
      borderRadiusSM: 99,
    },
    Modal: {},
    List: {
      colorTextDescription: "black",
      colorBorder: "transparent",
    },
    Menu: {
      darkSubMenuItemBg: palette.black,
    },
    Badge: {
      colorText: 'inherit'
    }
  },
  cssVar: true, hashed: false
};

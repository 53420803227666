import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultApiConfig } from "./index";

export const authApi = createApi({
  ...defaultApiConfig,
  reducerPath: "authApi",
  endpoints: (builder) => ({
    logIn: builder.mutation({
      query: (body) => ({
        url: "admin/admins/login",
        method: "POST",
        body,
      }),
    }),
    generateValidationCode: builder.mutation<
      { error: string },
      { email?: string }
    >({
      query: (body) => ({
        url: "admin/admin/generate-validation-code",
        method: "POST",
        body,
      }),
    }),
    updateAdminPassword: builder.mutation({
      query: (body) => ({
        url: "admin/admin/password",
        method: "PATCH",
        body,
      }),
    }),
  }),
});

export const {
  useLogInMutation,
  useGenerateValidationCodeMutation,
  useUpdateAdminPasswordMutation,
} = authApi;

import { Form, Input, Modal } from "antd";
import { $Object } from "types/object";
import { useParams } from "react-router-dom";

interface SetPasswordModalInterface {
  onCancel: () => void;
  mutation: (values: $Object) => void;
  isLoading: boolean
}

export const SetPasswordModal = ({
  onCancel,
  mutation,
  isLoading
}: SetPasswordModalInterface) => {
  const { userId } = useParams();
  const [form] = Form.useForm();

  return (
    <Modal
      open={true}
      title="Reseteaza parola"
      okText="Reseteaza"
      cancelText="Inchide"
      styles={{ body: { margin: "1rem 0" } }}
      onCancel={onCancel}
      okButtonProps={{ loading: isLoading }}
      onOk={() => mutation({ id: userId, ...form.getFieldsValue() })}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="password"
          label="Parola:"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Repeta parola:"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Parola introdusa nu coincide cu prima!")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useHash = (hash: string) => {
  const navigate = useNavigate();
  const { hash: oldHash } = useLocation();
  const putHash = useRef(() => {
    if (oldHash.replace("#", "") !== hash) {
      navigate({ hash });
    }
  });

  React.useEffect(() => {
    putHash.current();
  }, []);
};

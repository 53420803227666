import { useState } from "react";
import { Select, SelectProps, Spin } from "antd";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { QueryDefinition } from "@reduxjs/toolkit/query";
import { WithPagination } from "../api";

interface SmartSelectInterface extends SelectProps {
  query: UseQuery<
    QueryDefinition<any, any, string, WithPagination<any>, string>
  >;
}

export const SmartSelectPagination = ({
  query,
  ...props
}: SmartSelectInterface) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>();

  const { data, isLoading, isFetching } = query({ page, search });

  return (
    <Select
      loading={isLoading || isFetching}
      mode="multiple"
      className="capitalize"
      options={
        data?.data.map((i) => ({
          value: i.id,
          label: (
            <>
              {i.email}({i.firstName} {i.lastName})
            </>
          ),
          title: `${i.firstName} ${i.lastName}`,
        })) || []
      }
      dropdownRender={(menu) => (
        <>
          {menu}

          <Spin
            spinning={isFetching}
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%)",
              bottom: ".5rem",
            }}
          />
        </>
      )}
      onPopupScroll={(e) => {
        const { target }: { target: any } = e;

        if (
          target.scrollTop + target.offsetHeight === target.scrollHeight &&
          !isFetching &&
          (data?.pagination.currentPage || 1) < (data?.pagination.lastPage || 1)
        ) {
          setPage((prevState) => ++prevState);
        }
      }}
      onSearch={(val) => {
        setSearch(val);
        setPage(1);
      }}
      {...props}
    />
  );
};

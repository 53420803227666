import { useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Flex, Input, Layout, Select, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { format, getRouteByName, palette } from "libs";
import { useCustomToken, useFilters } from "hooks";
import { useGetUsersQuery, UserInterface, UserStatuses, UserTypes } from "api";
import { enumKeys } from "types/object";
import { useAppSelector } from "store/hooks";
import { Put } from "components";

interface UsersFiltersInterface {
  search?: string;
  "filters[email][$contains]"?: string;
  "filters[type][$eq]"?: UserTypes;
  "filters[status][$eq]"?: number[];
  page: number;
}

export const UsersPage = () => {
  const { token } = useCustomToken();
  const navigate = useNavigate();
  const { t } = useTranslation()

  const { filters, onFiltersChange } = useFilters<UsersFiltersInterface>(
    {
      page: 1,
      search: undefined,
      "filters[email][$contains]": undefined,
      "filters[type][$eq]": undefined,
      "filters[status][$eq]": undefined,
    },
    { keyword: "users" }
  );

  const { data, isLoading, isFetching } = useGetUsersQuery(filters);

  const { isAll, isUsersRead } = useAppSelector(
    (state) => state.loggedAdmin.parsedPermissions
  );

  useEffect(() => {
    if (!isUsersRead && !isAll) {
      navigate(getRouteByName("NotFoundPage"));
    }
  }, [isUsersRead, isAll]);

  const usersColumns = useMemo<ColumnsType<UserInterface>>(
    () => [
      {
        title: "Name",
        render: (user) => (
          <Link to={getRouteByName("UserPage", { userId: user.id })}>
            {user.firstName} {user.lastName}
          </Link>
        ),
      },
      {
        title: "Email",
        render: (user) => (
          <Link to={getRouteByName("UserPage", { userId: user.id })}>
            {user.email}
          </Link>
        ),
      },
      {
        title: "Tip",
        dataIndex: "type",
        render: (type) => t(UserTypes[type]),
      },
      {
        title: "Statut",
        dataIndex: "status",
        render: (status) => t(UserStatuses[status]),
      },
      {
        title: "Verificat",
        dataIndex: "isVerified",
        render: (isVerified) => (isVerified ? "Da" : "Nu"),
      },
      {
        title: "Motiv",
        dataIndex: "reason",
        render: (reason) => <Put>{reason}</Put>,
      },
      {
        title: "Sters",
        dataIndex: "deleted_at",
        render: (deletedAt) => deletedAt 
          ?(
            <Space>
              <Typography.Text>{format(deletedAt)}</Typography.Text>
              <Typography.Text>{format(deletedAt, "HH:mm A")}</Typography.Text>
            </Space>
          ) : null,
      },
    ],
    [token]
  );

  return (
    <>
      <Flex justify="space-between" style={{ marginBottom: "1rem" }}>
        <Space wrap>
          <Input.Search
            placeholder="Cauta nume"
            value={filters.search}
            onChange={(e) =>
              onFiltersChange({
                search: e.target.value,
              })
            }
            allowClear
          />

          <Select
            style={{ width: 150 }}
            placeholder="Alege tip"
            allowClear
            value={filters["filters[type][$eq]"]}
            onChange={(val) => onFiltersChange({ "filters[type][$eq]": val })}
          >
            {enumKeys(UserTypes).map((i) => (
              <Select.Option key={UserTypes[i]} value={UserTypes[i]}>
                {t(i)}
              </Select.Option>
            ))}
          </Select>

          <Select
            style={{ width: 150 }}
            placeholder="Alege statut"
            allowClear
            value={filters["filters[status][$eq]"]}
            onChange={(val) => onFiltersChange({ "filters[status][$eq]": val })}
          >
            {enumKeys(UserStatuses).map((i) => (
              <Select.Option key={i} value={UserStatuses[i]}>
                {t(i)}
              </Select.Option>
            ))}
          </Select>
        </Space>
      </Flex>
      <Layout style={{ backgroundColor: palette.white }}>
        <Table
          columns={usersColumns}
          loading={isLoading || isFetching}
          dataSource={data?.data}
          rowKey="id"
          scroll={{ x: 800 }}
          pagination={{
            total: data?.pagination.total,
            current: data?.pagination.currentPage,
            pageSize: data?.pagination.itemsPerPage,
            onChange: (page) => onFiltersChange({ page }),
            showSizeChanger: false,
          }}
        />
      </Layout>
    </>
  );
};

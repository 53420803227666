import { useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  DatePicker,
  Flex,
  Input,
  Layout,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { ClockCircleOutlined, CommentOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { format, getRouteByName, palette, today } from "libs";
import { useFilters, useHash } from "hooks";
import {
  ListingInterface,
  ListingsStatuses,
  ListingsTypes,
  useGetCountiesQuery,
  useGetCountriesQuery,
  useGetListingsQuery,
  useGetUsersInfiniteQuery,
} from "api";
import { Put, SmartSelect, SmartSelectPagination } from "components";
import { enumKeys } from "types/object";
import { useAppSelector } from "store/hooks";

interface UsersFiltersInterface {
  "filters[title][$contains]"?: string;
  "filters[type][$eq]"?: string;
  "filters[status][$eq]"?: string;
  "filters[user][id][$eq]"?: number[];
  "filters[country][$eq]"?: string;
  "filters[county][$eq]"?: string;
  "filters[dates][$gt]"?: string;
  "filters[dates][$lte]"?: string;
  "filters[created_at][$gt]"?: string;
  "filters[created_at][$lte]"?: string;
  page: number;
}

interface ListingsPageInterface {
  filterKey?: string;
}

export const ListingsPage = ({ filterKey }: ListingsPageInterface) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { filters, onFiltersChange } = useFilters<UsersFiltersInterface>(
    {
      page: 1,
      "filters[title][$contains]": undefined,
      "filters[type][$eq]": undefined,
      "filters[status][$eq]": undefined,
      "filters[user][id][$eq]": undefined,
      "filters[country][$eq]": undefined,
      "filters[county][$eq]": undefined,
      "filters[dates][$gt]": undefined,
      "filters[dates][$lte]": undefined,
      "filters[created_at][$gt]": undefined,
      "filters[created_at][$lte]": undefined,
    },
    { keyword: filterKey || "listings" }
  );

  const { isAll, isPostsRead, isUsersRead, isCommentsRead } = useAppSelector(
    (state) => state.loggedAdmin.parsedPermissions
  );

  const { data, isLoading, isFetching } = useGetListingsQuery(filters);

  useEffect(() => {
    if (!isAll && !isPostsRead) {
      navigate(getRouteByName("NotFoundPage"));
    }
  }, [isAll, isPostsRead, navigate]);

  useHash("posts");

  useEffect(() => {
    if (userId) {
      onFiltersChange({ "filters[user][id][$eq]": [Number(userId)] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const usersColumns = useMemo<ColumnsType<ListingInterface>>(
    () => [
      {
        title: "Title",
        dataIndex: "title",
        render: (name, listing) => (
          <Link to={getRouteByName("ListingPage", { listingId: listing.id })}>
            {name}
          </Link>
        ),
      },
      {
        title: "Tip",
        dataIndex: "type",
        render: (type) => t(ListingsTypes[type]),
      },
      {
        title: "Statut",
        dataIndex: "status",
        render: (status) => t(ListingsStatuses[status]),
      },
      {
        title: "Utilizator",
        dataIndex: "user",
        render: (user) =>
          isUsersRead ? (
            <Link to={getRouteByName("UserPage", { userId: user.id })}>
              {user.firstName} {user.lastName}
            </Link>
          ) : (
            <>
              {user.firstName} {user.lastName}
            </>
          ),
      },
      {
        title: "Vizualizari",
        dataIndex: "views",
      },
      {
        title: "Like-uri",
        dataIndex: "likes",
      },
      {
        title: "Motiv",
        dataIndex: "reason",
        render: (reason) => <Put>{reason}</Put>,
      },
      {
        title: "Data publicarii",
        dataIndex: "dates",
        render: (_, listings) => (
          <Put>
            {listings.dates.map((i) => (
              <Space>
                <Typography.Text>{format(i)}</Typography.Text>
                {dayjs(i) > today && <ClockCircleOutlined />}
              </Space>
            ))}
          </Put>
        ),
      },
      {
        title: "Data crearii",
        dataIndex: "createdAt",
        render: (createdAt) => (
          <Space direction="vertical" size={0}>
            <Typography.Text>{format(createdAt)}</Typography.Text>
            <Typography.Text>{format(createdAt, "HH:mm A")}</Typography.Text>
          </Space>
        ),
      },
      {
        align: "end",
        fixed: "right",
        width: 50,
        render: (_, listing) => (
          <Space>
            {isCommentsRead && (
              <Link
                to={{
                  pathname: getRouteByName("ListingPage", {
                    listingId: listing.id,
                  }),
                  hash: "#comments",
                }}
              >
                <CommentOutlined
                  style={{ fontSize: "1rem", cursor: "pointer" }}
                />
              </Link>
            )}
          </Space>
        ),
      },
    ],
    [isCommentsRead, isUsersRead, t]
  );

  const onDateChange = (date: any) => {
    onFiltersChange({
      "filters[dates][$gt]":
        date && date[0] ? date[0].format("YYYY-MM-DDT00:00Z") : undefined,
      "filters[dates][$lte]":
        date && date[1] ? date[1].format("YYYY-MM-DDT23:59Z") : undefined,
    });
  };

  const onCreatedAtChange = (date: any) => {
    onFiltersChange({
      "filters[created_at][$gt]":
        date && date[0] ? date[0].format("YYYY-MM-DDT00:00Z") : undefined,
      "filters[created_at][$lte]":
        date && date[1] ? date[1].format("YYYY-MM-DDT23:59Z") : undefined,
    });
  };

  return (
    <>
      <Flex justify="space-between" style={{ marginBottom: "1rem" }}>
        <Space wrap>
          <Input.Search
            placeholder="Cauta titlu"
            value={filters["filters[title][$contains]"]}
            onChange={(e) =>
              onFiltersChange({ "filters[title][$contains]": e.target.value })
            }
            allowClear
          />
          <Select
            style={{ width: 150 }}
            placeholder="Alege tip"
            allowClear
            defaultValue={filters["filters[type][$eq]"]}
            value={filters["filters[type][$eq]"]}
            onChange={(val) => onFiltersChange({ "filters[type][$eq]": val })}
          >
            {enumKeys(ListingsTypes).map((i) => (
              <Select.Option key={i} value={ListingsTypes[i]}>
                {t(i)}
              </Select.Option>
            ))}
          </Select>
          <Select
            style={{ width: 150 }}
            placeholder="Alege statut"
            allowClear
            value={filters["filters[status][$eq]"]}
            onChange={(val) => onFiltersChange({ "filters[status][$eq]": val })}
          >
            {enumKeys(ListingsStatuses).map((i) => (
              <Select.Option key={i} value={ListingsStatuses[i]}>
                {t(i)}
              </Select.Option>
            ))}
          </Select>
          {!userId && (
            <SmartSelectPagination
              query={useGetUsersInfiniteQuery}
              style={{ minWidth: 200 }}
              placeholder="Alege utilizator"
              value={filters["filters[user][id][$eq]"]}
              onChange={(val) =>
                onFiltersChange({ "filters[user][id][$eq]": val })
              }
              filterOption={false}
              allowClear
              showSearch
            />
          )}
          <SmartSelect
            query={useGetCountriesQuery}
            style={{ width: 150 }}
            placeholder="Alege tara"
            value={filters["filters[country][$eq]"]}
            onChange={(val) =>
              onFiltersChange({ "filters[country][$eq]": val })
            }
            mode="multiple"
            allowClear
            optionFilterProp="children"
            showSearch
          />
          <SmartSelect
            query={useGetCountiesQuery}
            style={{ width: 160 }}
            placeholder="Alege localitatea"
            value={filters["filters[county][$eq]"]}
            onChange={(val) => onFiltersChange({ "filters[county][$eq]": val })}
            optionFilterProp="children"
            mode="multiple"
            showSearch
            allowClear
          />

          <DatePicker.RangePicker
            defaultValue={
              filters["filters[created_at][$gt]"] !== undefined
                ? [
                    dayjs(filters["filters[created_at][$gt]"], "YYYY-MM-DD"),
                    dayjs(filters["filters[created_at][$lte]"], "YYYY-MM-DD"),
                  ]
                : undefined
            }
            placeholder={["Publicat de la", "Publicat pina la"]}
            onChange={onDateChange}
          />

          <DatePicker.RangePicker
            defaultValue={
              filters["filters[created_at][$gt]"] !== undefined
                ? [
                    dayjs(filters["filters[created_at][$gt]"], "YYYY-MM-DD"),
                    dayjs(filters["filters[created_at][$lte]"], "YYYY-MM-DD"),
                  ]
                : undefined
            }
            onChange={onCreatedAtChange}
            placeholder={["Creat de la", "Creat pina la"]}
          />
        </Space>
      </Flex>
      <Layout style={{ backgroundColor: palette.white }}>
        <Table
          columns={usersColumns}
          loading={isLoading || isFetching}
          dataSource={data?.data}
          rowKey="id"
          pagination={{
            total: data?.pagination.total,
            current: data?.pagination.currentPage,
            pageSize: data?.pagination.itemsPerPage,
            onChange: (page) => onFiltersChange({ page }),
            showSizeChanger: false,
          }}
          scroll={{ x: 1200 }}
        />
      </Layout>
    </>
  );
};

import { Select, SelectProps } from "antd";
import { useGetPermissionsQuery } from "api/common";

export const PermissionsSelect = (props: SelectProps) => {
  const { data, isLoading } = useGetPermissionsQuery("permissions");

  return (
    <Select
      loading={isLoading}
      mode="multiple"
      className="capitalize"
      {...props}
    >
      {data?.map((i) => (
        <Select.Option key={i.id} value={i.id} className="capitalize">
          {i.name}
        </Select.Option>
      ))}
    </Select>
  );
};

import { useGetRoomMessagesQuery } from "api/chat";
import { Avatar, List, Space, Spin, theme } from "antd";
import { Link, useParams } from "react-router-dom";
import { chatDateTime, format, getRouteByName } from "libs";
import { UIEvent, useState } from "react";

interface RoomMessagesInterface {
  roomId?: number;
}

export const RoomMessages = ({ roomId }: RoomMessagesInterface) => {
  const { userId } = useParams();
  const { token } = theme.useToken();

  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useGetRoomMessagesQuery({
    page,
    id: roomId,
  });

  const onScroll = (e: UIEvent<HTMLDivElement>) => {
    const { target }: { target: any } = e;

    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      !isFetching &&
      (data?.pagination.currentPage || 1) < (data?.pagination.lastPage || 1)
    ) {
      setPage((prevState) => ++prevState);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div
        style={{ maxHeight: 500, overflow: "auto", minHeight: 200 }}
        onScroll={onScroll}
      >
        {data?.data.map((message) => (
          <List.Item.Meta
            avatar={
              <Avatar
                src={message?.user.profilePicture}
                style={{ position: "relative", zIndex: 1 }}
              />
            }
            title={
              <Space>
                <Link
                  to={getRouteByName("UserPage", { userId: message?.user.id })}
                  style={{ fontWeight: 400 }}
                >
                  {message?.user.firstName}
                  {message?.user.lastName}
                </Link>
              </Space>
            }
            description={
              <div style={{ width: "fit-content" }}>
                <div
                  style={{
                    borderRadius: 8,
                    background:
                      Number(userId) === message.user.id
                        ? `${token.colorPrimary}30`
                        : `#00000020`,
                    padding: "4px 8px",
                  }}
                >
                  {message?.message}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "grey",
                    fontWeight: 300,
                  }}
                >
                  {format(message?.created_at, chatDateTime)}
                </div>
              </div>
            }
            style={{ position: "relative", marginBottom: ".5rem" }}
          />
        ))}
      </div>
    </Spin>
  );
};

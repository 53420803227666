import { initializeApp } from "firebase/app";

import { getMessaging } from "firebase/messaging";

//Firebase Config values imported from .env file
const firebaseConfig = {
  apiKey: "AIzaSyCsWNW54IUeAdTxk2cnZ8BveOls_e-E0lA",
  authDomain: "astech-spor-61971.firebaseapp.com",
  projectId: "astech-spor-61971",
  storageBucket: "astech-spor-61971.appspot.com",
  messagingSenderId: "88845933528",
  appId: "1:88845933528:web:78e5530044f40711b1f0ad",
  measurementId: "G-9B9M655JKJ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);
import { useState } from "react";
import { Flex, Image, Space, theme } from "antd";
import { EyeOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { MediaFile } from "../api";

interface IGallery {
  mediaFiles?: MediaFile[];
}

export const Gallery = ({ mediaFiles = [] }: IGallery) => {
  const { token } = theme.useToken();

  const [currentVisible, setCurrentVisible] = useState<number>();

  return (
    <Image.PreviewGroup
      preview={{
        imageRender: ({ props }) => {
          if (props.src?.includes("mp4")) {
            return <video src={props.src} controls />;
          }

          return <Image src={props.src} />;
        },
        toolbarRender: (originalNode) => {
          if (originalNode.props.src?.includes("mp4")) {
            return false;
          }

          return originalNode;
        },
        onChange: (current) => {
          setCurrentVisible(current);
        },
        onVisibleChange: (value) => {
          if (!value) setCurrentVisible(undefined);
        },
        current: currentVisible,
        visible: currentVisible !== undefined,
      }}
    >
      <Space>
        {mediaFiles.map((i, current) => {
          if (i.mediaType === 2) {
            return (
              <div
                style={{ position: "relative" }}
                className="ant-image"
                key={current}
              >
                <div
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 8,
                    zIndex: 2,
                  }}
                  className="ant-image-mask"
                >
                  <Flex gap={12}>
                    <EyeOutlined
                      style={{ fontSize: 20 }}
                      onClick={() => setCurrentVisible(current)}
                    />
                  </Flex>
                </div>

                <PlayCircleOutlined
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    zIndex: 1,
                    color: "white",
                    transform: "translate(-50%, -50%)",
                    fontSize: 32,
                  }}
                />

                <video
                  src={i.url}
                  style={{
                    objectFit: "cover",
                    borderRadius: 8,
                    width: 100,
                    height: 100,
                    backgroundColor: token.colorBorder,
                  }}
                />

                <Image src={i.url} width={0} height={0} />
              </div>
            );
          }

          return (
            <Image
              key={i.url}
              src={i.url}
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                borderRadius: 8,
              }}
              wrapperStyle={{ width: 100, height: 100 }}
              preview={{
                mask: (
                  <Flex gap={12}>
                    <EyeOutlined
                      style={{ fontSize: 20 }}
                      onClick={() => setCurrentVisible(current)}
                    />
                  </Flex>
                ),
                maskClassName: "rounded-lg",
              }}
            />
          );
        })}
      </Space>
    </Image.PreviewGroup>
  );
};

import { App, Form, Input, Modal } from "antd";
import { PermissionsSelect } from "./PermissionsSelect";
import { useCreateRoleMutation } from "api";

interface RoleFormInterface {
  onCancel: () => void;
}

export const RoleForm = ({ onCancel }: RoleFormInterface) => {
  const [form] = Form.useForm();
  const { notification } = App.useApp();

  const [createRoleMutation, { isLoading }] = useCreateRoleMutation();

  const onOkClick = () =>
    createRoleMutation(form.getFieldsValue())
      .then(() => {
        notification.success({
          message: `Rolul a fost creat cu success`,
        });
        onCancel();
      })
      .catch((e) => {
        notification.error({ message: "Rolul nu a putut fi creat" });
        console.log(e);
      });

  return (
    <Modal
      title="Adauga rol"
      okText="Creeaza"
      onOk={onOkClick}
      okButtonProps={{ loading: isLoading }}
      onCancel={onCancel}
      cancelText="Inchide"
      open
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ width: "80%" }}
      >
        <Form.Item label="Nume" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Permisiuni" name="permissions">
          <PermissionsSelect />
        </Form.Item>
      </Form>
    </Modal>
  );
};

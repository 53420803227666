import { UIEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { List, Spin } from "antd";
import { useGetUserRoomsQuery } from "api/chat";
import { RoomItem } from "./RoomItem";
import { useAppSelector } from "store/hooks";
import { getRouteByName } from "libs";

export const UserChat = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useGetUserRoomsQuery({
    page,
    id: Number(userId),
  });

  const { isAll, isChatRead } = useAppSelector(
    (state) => state.loggedAdmin.parsedPermissions
  );

  useEffect(() => {
    if (!isAll && !isChatRead) {
      navigate(getRouteByName("NotFoundPage"));
    }
  }, [isChatRead]);

  const onScroll = (e: UIEvent<HTMLDivElement>) => {
    const { target }: { target: any } = e;

    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      !isFetching &&
      (data?.pagination.currentPage || 1) < (data?.pagination.lastPage || 1)
    ) {
      setPage((prevState) => ++prevState);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <List itemLayout="horizontal" dataSource={data?.data}>
        <div
          onScroll={onScroll}
          style={{
            height: "calc(100vh - 300px)",
            minHeight: 300,
            overflow: "auto",
          }}
        >
          {data?.data.map((room) => (
            <RoomItem key={room.chatRoom} room={room} />
          ))}

          <Spin
            spinning={Boolean(data?.data.length) && isFetching}
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%)",
              bottom: ".5rem",
            }}
          />
        </div>
      </List>
    </Spin>
  );
};

import { today } from "../libs";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

export const getRightInterval = () => {
  const currentYear = today.year();

  if (currentYear === 2024) {
    return currentYear;
  }

  return `2024-${currentYear}`;
};

export const getErrorMessage = (
  error: FetchBaseQueryError | SerializedError
) => {
  if ("status" in error) {
    if ("error" in error) {
      return error.error;
    }

    return JSON.stringify(error.data);
  } else {
    return error.message;
  }
};

import { ShorUserInterface } from "./users.types";

export interface ReportInterface {
  id: number;
  reporter: ShorUserInterface;
  user: ShorUserInterface;
  post: {
    id: number;
    title: string;
  };
  has_spam: number;
  has_racism: number;
  has_porn: number;
  has_bad_words: number;
  status: ReportsStatuses;
  description: string;
  create_at: string;
  updated_at: string;
}

export enum ReportsStatuses {
  PENDING = 1,
  RESOLVED = 2
}

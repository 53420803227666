import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultApiConfig } from "./apiSettings";
import { UserInterface } from "./users.types";
import { WithPagination } from "./common.types";
import { AdminInterface } from "./admins.types";
import { $Object } from "../types/object";
import { defaultQueryStringify } from "libs";

export const adminsApi = createApi({
  ...defaultApiConfig,
  reducerPath: "adminsApi",
  tagTypes: ["Admins", "AdminsStats"],
  endpoints: (builder) => ({
    getAdmins: builder.query<WithPagination<AdminInterface>, $Object>({
      query: (params) => `admin/admins?${defaultQueryStringify(params)}`,
      providesTags: ["Admins"],
    }),
    getAdmin: builder.query<UserInterface, { id: number }>({
      query: ({ id }) => `admin/admins/${id}`,
    }),
    getLoggedAdmin: builder.query<AdminInterface, undefined>({
      query: () => `admin`,
    }),
    createAdmin: builder.mutation({
      query: (body) => ({
        url: `admin/admins/register`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Admins"],
    }),
    updateAdmin: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `admin/admin/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Admins"],
    }),
    deleteAdmin: builder.mutation({
      query: (id) => ({ url: `admin/admin/${id}`, method: "DELETE" }),
      invalidatesTags: ["Admins"],
    }),
    getAdminStats: builder.query<
      { ListingsPage: number; ReportsPage: number },
      undefined
    >({
      query: () => "/admin/stats",
      transformResponse: (res: { posts: number; reports: number }) => ({
        ListingsPage: res?.posts,
        ReportsPage: res?.reports,
      }),
      providesTags: ["AdminsStats"]
    }),
  }),
});

export const {
  useGetAdminsQuery,
  useGetAdminQuery,
  useGetLoggedAdminQuery,
  useCreateAdminMutation,
  useUpdateAdminMutation,
  useDeleteAdminMutation,
  useGetAdminStatsQuery,
} = adminsApi;

import React, { PropsWithChildren } from "react";

interface PutInterface {
  condition?: boolean;
}

export const Put = ({
  condition = true,
  children,
}: PropsWithChildren<PutInterface>) => {
  return <>{condition && children ? children : "—"}</>;
};

import { createSlice } from "@reduxjs/toolkit";
import { load } from "react-cookies";
import { AdminInterface } from "api";
import { $Object } from "types/object";

interface CounterState {
  auth: { token?: string };
  data?: AdminInterface;
  parsedPermissions: $Object;
}

const initialState: CounterState = {
  auth: { token: load("access-token") },
  parsedPermissions: {},
};

export const loggedAdminSlice = createSlice({
  name: "app-settings",
  initialState,
  reducers: {
    setAuthTokens: (state, action) => ({ ...state, auth: action.payload }),
    setLoggedAdminData: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { setAuthTokens, setLoggedAdminData } = loggedAdminSlice.actions;

export default loggedAdminSlice.reducer;

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Alert,
  App,
  Button,
  Form,
  Input,
  Space,
  theme,
  Typography,
} from "antd";
import { formErrorHandler, getRouteByName, palette } from "libs";
import { useObjectState, useThrottledState } from "hooks";

interface SetPasswordFormInterface {
  onFinish: (values: any) => void;
  isFinishLoading: boolean;
  isSuccess: boolean;
  error: any;
  onResendEmailCode: () => void;
}

export const SetPasswordForm = ({
  onFinish,
  onResendEmailCode,
  isFinishLoading,
  isSuccess,
  error,
}: SetPasswordFormInterface) => {
  const { notification } = App.useApp();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [timer, setTimer] = useState(300);
  const [showResend, setShowResend] = useState(false);

  const [passwordRules, setPasswordRules] = useObjectState({
    isBigLetter: false,
    isSmallLetter: false,
    isNumber: false,
    isLength: false,
    isSymbol: false,
  });

  const [password, setPassword] = useThrottledState("", 500);

  useEffect(() => {
    setInterval(() => {
      setTimer((prevState) => {
        if (!showResend) {
          if (prevState === 1) {
            setShowResend(true);

            return 0;
          }

          return --prevState;
        }

        return prevState;
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: "Parola a fost resetaat cu success" });
      navigate(getRouteByName("LogInPage"));
    }
  }, [isSuccess, notification, navigate]);

  useEffect(() => {
    if (error) {
      form.setFields(formErrorHandler((error as any)?.data));
      notification.error({ message: "A aparut o eroare la resetarea parolei" });
    }
  }, [error, notification, form]);

  useEffect(() => {
    const onPasswordChangeValidation = () =>
      setPasswordRules({
        isLength: password?.length > 7,
        isBigLetter: /[A-Z]/.test(password),
        isSmallLetter: /[a-z]/.test(password),
        isNumber: /[0-9]/.test(password),
        // eslint-disable-next-line
        isSymbol: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|\-]/.test(password),
      });

    onPasswordChangeValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return (
    <Space direction="vertical" style={{ width: "20rem", gap: 0 }}>
      <Typography.Title style={{ margin: 0 }}>Bine ai venit</Typography.Title>
      <Typography.Text style={{ color: token.colorPrimary }}>
        Te rog seteaza-ti parola
      </Typography.Text>

      <Form
        form={form}
        style={{ marginTop: "2rem" }}
        onFinish={onFinish}
        onValuesChange={(_, { password }) => setPassword(password || "")}
      >
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Acest camp este obligatoriu",
            },
          ]}
        >
          <Input.Password placeholder="Parola" size="large" />
        </Form.Item>

        <Space wrap style={{ marginBottom: "1.4rem" }}>
          <Alert
            message="O litera mica"
            type={passwordRules.isSmallLetter ? "success" : "error"}
            showIcon
          />
          <Alert
            message="O litera mare"
            type={passwordRules.isBigLetter ? "success" : "error"}
            showIcon
          />
          <Alert
            message="Un numar"
            type={passwordRules.isNumber ? "success" : "error"}
            showIcon
          />
          <Alert
            message="Un simbol"
            type={passwordRules.isSymbol ? "success" : "error"}
            showIcon
          />
          <Alert
            message="Minim 8 caractere"
            type={passwordRules.isLength ? "success" : "error"}
            showIcon
          />
        </Space>

        <Form.Item
          name="password2"
          rules={[
            {
              required: true,
              message: "Acest camp este obligatoriu",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Nu coincide cu parola precedenta")
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Repeta parola" size="large" />
        </Form.Item>

        <Form.Item name="code">
          <Input
            size="large"
            placeholder="Codul din email"
            suffix={
              <Space align="center">
                {showResend ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      onResendEmailCode();
                      setShowResend(false);
                      setTimer(300);
                    }}
                    size="small"
                  >
                    Retrimi-te
                  </Button>
                ) : (
                  <span style={{ color: token.colorPrimary }}>{timer} sec</span>
                )}
              </Space>
            }
          />
        </Form.Item>

        <Button
          type="primary"
          style={{ width: "100%" }}
          loading={isFinishLoading}
          htmlType="submit"
          size="large"
        >
          {"Salveaza parola"}
        </Button>
      </Form>

      <Space
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "1rem 0 0",
        }}
      >
        <Link to={getRouteByName("LogInPage")}>
          <Typography.Text style={{ color: palette.primaryColor }}>
            Logheaza-te
          </Typography.Text>
        </Link>
      </Space>
    </Space>
  );
};

import { Avatar, List, Space, theme } from "antd";
import { getDiffFromToday } from "../libs";
import { Comment } from "../api";
import { Dispatch, SetStateAction, useState } from "react";
import { Trans } from "react-i18next";

interface CommentsRepliesInterface {
  replies?: Comment[];
  onShowRepliesChange: Dispatch<SetStateAction<boolean>>;
}

export const CommentsReplies = ({
  replies,
  onShowRepliesChange,
}: CommentsRepliesInterface) => {
  const { token } = theme.useToken();

  const [showReplies, setShowReplies] = useState(false);

  return (
    <div style={{ marginLeft: "3rem", marginTop: ".25rem" }}>
      {Boolean(replies?.length) && !showReplies && (
        <span
          style={{ color: "grey", cursor: "pointer" }}
          onClick={() => {
            setShowReplies((prevState) => !prevState);
            onShowRepliesChange((prevState) => !prevState);
          }}
        >
          Show replies
        </span>
      )}

      <span>
        {showReplies &&
          replies?.map((reply, key) => (
            <List.Item style={{ position: "relative" }}>
              <List.Item.Meta
                avatar={<Avatar src={reply.user.profilePicture} />}
                title={
                  <Space>
                    <span>
                      {reply.user.firstName}
                      {reply.user.lastName}
                    </span>

                    <span style={{ color: "grey", fontWeight: 300 }}>
                      <Trans {...getDiffFromToday(reply.createdAt)}></Trans>
                    </span>
                  </Space>
                }
                description={reply.comment}
              />
              <div
                style={{
                  position: "absolute",
                  top: ".3rem",
                  left: "-2rem",
                  borderLeft: "1px solid" + token.colorPrimary,
                  borderBottom: "1px solid" + token.colorPrimary,
                  borderBottomLeftRadius: "1rem",
                  width: "2rem",
                  height: "1.5rem",
                }}
              />
              {key > 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: "-60px",
                    left: "-2rem",
                    borderLeft: "1px solid" + token.colorPrimary,
                    width: "2rem",
                    height: "4.5rem",
                  }}
                />
              )}
            </List.Item>
          ))}
      </span>
    </div>
  );
};

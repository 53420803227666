import { default as queryString } from "query-string";
import { $Object } from "../types/object";

export const camelize = (str: string) => {
  return str.replace(/^(\w)|\W+(.)/g, function (match, chr) {
    if (match[1]) {
      return match[1].toUpperCase();
    }

    return chr.toUpperCase();
  });
};

export const defaultQueryStringify = (params: $Object) =>
  queryString.stringify(params, {
    skipEmptyString: true,
    skipNull: true,
    arrayFormat: "comma",
  });

export const QSnoNull = (params: Record<string, string>) =>
  queryString.stringify(params, {
    skipEmptyString: true,
    arrayFormat: "comma",
  });

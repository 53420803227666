import { App, Form, Input, Modal } from "antd";
import {
  AdminInterface,
  useCreateAdminMutation,
  useUpdateAdminMutation,
} from "api";
import { RolesSelect } from "./RolesSelect";
import { useEffect } from "react";

interface AdminFormInterface {
  admin?: AdminInterface;
  onCancel: () => void;
}

export const AdminForm = ({ admin, onCancel }: AdminFormInterface) => {
  const [form] = Form.useForm();
  const { notification } = App.useApp();

  const [
    createRoleMutation,
    { isLoading: isCreateLoading, error: createError },
  ] = useCreateAdminMutation();

  const [
    updateRoleMutation,
    { isLoading: isUpdateLoading, error: updateError },
  ] = useUpdateAdminMutation();

  useEffect(() => {
    if (createError) {
      console.log(createError, "createError");
    }
  }, [createError]);

  const onOkClick = () => {
    const formValues = form.getFieldsValue();

    (admin
      ? updateRoleMutation({ id: admin.id, ...formValues })
      : createRoleMutation(formValues)
    )
      .then((res) => {
        // if (res.error) {
        // }

        console.log(res, "res");
        notification.success({
          message: `Adminul a fost ${admin ? "editat" : "creat"} cu success`,
        });
        onCancel();
      })
      .catch((e) => {
        notification.error({ message: "Rolul nu a putut fi creat" });
        console.log(e);
      });
  };

  return (
    <Modal
      title={admin ? "Editeaza admin" : "Adauga admin"}
      onOk={onOkClick}
      okText={admin ? "Editeaza" : "Creeaza"}
      okButtonProps={{ loading: isCreateLoading || isUpdateLoading }}
      onCancel={onCancel}
      cancelText="Inchide"
      styles={{ body: { margin: "2rem 0" } }}
      open
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ width: "80%" }}
      >
        <Form.Item
          label="Prenume"
          name="firstName"
          initialValue={admin?.firstName}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Nume" name="lastName" initialValue={admin?.lastName}>
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email" initialValue={admin?.email}>
          <Input />
        </Form.Item>
        <Form.Item label="Rol" name="role" initialValue={admin?.roles.id}>
          <RolesSelect />
        </Form.Item>
        {!admin && (
          <Form.Item label="Parola" name="password">
            <Input.Password />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

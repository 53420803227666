import { Select, SelectProps } from "antd";
import { useGetRolesQuery } from "api";

export const RolesSelect = (props: SelectProps) => {
  const { data, isLoading } = useGetRolesQuery({});

  return (
    <Select loading={isLoading} className="capitalize" {...props}>
      {data?.data.map((i) => (
        <Select.Option key={i.id} value={i.id} className="capitalize">
          {i.name}
        </Select.Option>
      ))}
    </Select>
  );
};

import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  App,
  Button,
  Dropdown,
  Flex,
  Grid,
  Input,
  Layout,
  Select,
  Space,
  Table,
} from "antd";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { getRouteByName, palette } from "libs";
import { DeleteModal } from "components";
import {
  ServiceInterface,
  useDeleteServiceMutation,
  useGetCategoriesQuery,
  useGetServicesQuery,
  useUpdateServiceMutation,
} from "api";
import { useFilters } from "hooks";
import { ServiceForm } from "./ServiceForm";
import { useAppSelector } from "store/hooks";

interface RolesFiltersInterface {
  "filters[name][$contains]"?: string;
  "filters[category][id][$eq]"?: number[];
}

export const ServicesPage = () => {
  const { notification } = App.useApp();
  const navigate = useNavigate();

  const [showServiceForm, setShowServiceForm] = useState(false);
  const [deleteService, setDeleteService] = useState<ServiceInterface>();
  const [updatedService, setUpdatedService] = useState<
    Omit<ServiceInterface, "category"> & { category: number }
  >();

  const { filters, onFiltersChange } = useFilters<RolesFiltersInterface>(
    {
      "filters[name][$contains]": undefined,
      "filters[category][id][$eq]": undefined,
    },
    { keyword: "servcies" }
  );

  const {
    isServicesRead,
    isServicesCreate,
    isServicesEdit,
    isServicesDelete,
    isAll,
  } = useAppSelector((state) => state.loggedAdmin.parsedPermissions);

  const { data: dataServices, isLoading: isServicesLoading } =
    useGetServicesQuery(filters);

  const { data: dataCategories, isLoading: isCategoriesLoading } =
    useGetCategoriesQuery({});

  const [updateRoleMutation, { isLoading: isUpdateLoading }] =
    useUpdateServiceMutation();

  const [deleteRoleMutation, { isLoading: isDeleteLoading }] =
    useDeleteServiceMutation();

  const breakpoints = Grid.useBreakpoint();

  useEffect(() => {
    if (!isServicesRead && !isAll) {
      navigate(getRouteByName("NotFoundPage"));
    }
  }, [isServicesRead, isAll, navigate]);

  useEffect(() => {
    if (updatedService) {
      updateRoleMutation(updatedService)
        .then(() =>
          notification.success({
            message: "Categoria a fost modificat cu success",
          })
        )
        .catch(() =>
          notification.error({
            message: "Categoria nu poate fi modificat!",
          })
        )
        .finally(() => {
          setUpdatedService(undefined);
        });
    }
  }, [updatedService, updateRoleMutation, notification]);

  const usersColumns = useMemo<ColumnsType<ServiceInterface>>(
    () => [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Categorie",
        dataIndex: "category",
        render: (category, service) =>
          isAll || isServicesEdit ? (
            <Select
              value={dataCategories?.find((i) => i.id === category.id)?.id}
              onChange={(value) =>
                setUpdatedService({ ...service, category: value })
              }
              loading={
                isCategoriesLoading ||
                (updatedService?.id === service.id && isUpdateLoading)
              }
              style={{ minWidth: 150 }}
            >
              {dataCategories?.map((i) => (
                <Select.Option key={i.id} value={i.id}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          ) : (
            category.name
          ),
      },
      {
        title: "Actions",
        align: "end",
        width: 100,
        render: (service) =>
          isAll || isServicesDelete ? (
            <Dropdown
              menu={{
                items: [
                  {
                    label: "Delete",
                    key: "delete",
                    onClick: () => setDeleteService(service),
                  },
                ],
              }}
              trigger={["click"]}
              arrow
            >
              <MoreOutlined style={{ padding: 3 }} />
            </Dropdown>
          ) : null,
      },
    ],
    [
      isCategoriesLoading,
      isUpdateLoading,
      updatedService,
      isServicesDelete,
      isServicesEdit,
      dataCategories,
      isAll,
    ]
  );

  const onDeleteOkClick = () =>
    deleteRoleMutation(deleteService?.id)
      .then(() =>
        notification.success({
          message: "Serviciul a fost sters cu success",
        })
      )
      .catch(() =>
        notification.error({ message: "Serviciul nu poate fi sters!" })
      )
      .finally(() => setDeleteService(undefined));

  return (
    <>
      <Flex
        justify="space-between"
        style={{
          marginBottom: "1rem",
          flexDirection: breakpoints.xs ? "column-reverse" : undefined,
        }}
        gap={8}
      >
        <Space wrap>
          <Input.Search
            placeholder="Cauta denumire"
            value={filters["filters[name][$contains]"]}
            onChange={(e) =>
              onFiltersChange({ "filters[name][$contains]": e.target.value })
            }
            allowClear
          />
          <Select
            loading={isCategoriesLoading}
            mode="multiple"
            className="capitalize"
            placeholder="Categorii"
            value={filters["filters[category][id][$eq]"]}
            onChange={(categories) =>
              onFiltersChange({ "filters[category][id][$eq]": categories })
            }
            style={{ minWidth: 150 }}
            allowClear
          >
            {dataCategories?.map((i) => (
              <Select.Option key={i.id} value={i.id} className="capitalize">
                {i.name}
              </Select.Option>
            ))}
          </Select>
        </Space>
        {(isAll || isServicesCreate) && (
          <Button
            type="primary"
            onClick={() => setShowServiceForm(true)}
            style={{ color: "white" }}
            icon={<PlusOutlined />}
          >
            Adauga serviciu
          </Button>
        )}
      </Flex>
      <Layout style={{ backgroundColor: palette.white }}>
        <Table
          columns={usersColumns}
          loading={isServicesLoading}
          dataSource={dataServices}
          rowKey="id"
          scroll={{ x: 500 }}
        />
      </Layout>

      {showServiceForm && (
        <ServiceForm onCancel={() => setShowServiceForm(false)} />
      )}

      {deleteService?.id && (
        <DeleteModal
          title="Sterge rol"
          onCancel={() => setDeleteService(undefined)}
          onOk={onDeleteOkClick}
          okButtonProps={{ loading: isDeleteLoading }}
        >
          Esti sigur ca vrei sa stergi serviciul {deleteService?.name}
        </DeleteModal>
      )}
    </>
  );
};

import { useEffect, useMemo, useState } from "react";
import {
  App,
  Button,
  Dropdown,
  Flex,
  Input,
  Layout,
  Select,
  Space,
  Table,
} from "antd";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { palette } from "libs";
import { DeleteModal } from "components";
import {
  RoleInterface,
  useDeleteRoleMutation,
  useGetPermissionsQuery,
  useGetRolesQuery,
  useUpdateRoleMutation,
} from "api";
import { RoleForm } from "./components/RoleForm";
import { useFilters } from "hooks";

interface RolesFiltersInterface {
  name?: string;
  permissions?: number[];
}

export const RolesPage = () => {
  const { notification } = App.useApp();

  const [showRoleForm, setShowRoleForm] = useState(false);
  const [deleteRole, setDeleteRole] = useState<RoleInterface>();
  const [updateRole, setUpdateRole] = useState<
    Omit<RoleInterface, "permissions"> & { permissions: number[] }
  >();

  const { filters, onFiltersChange } = useFilters<RolesFiltersInterface>(
    { name: undefined, permissions: undefined },
    { keyword: "roles" }
  );

  const { data, isLoading } = useGetRolesQuery(filters);

  const { data: dataPermissions, isLoading: isPermissionsLoading } =
    useGetPermissionsQuery("permissions");

  const [updateRoleMutation, { isLoading: isUpdateLoading }] =
    useUpdateRoleMutation();

  const [deleteRoleMutation, { isLoading: isDeleteLoading }] =
    useDeleteRoleMutation();

  useEffect(() => {
    if (updateRole) {
      updateRoleMutation(updateRole)
        .then(() =>
          notification.success({
            message: "Rolul a fost modificat cu success",
          })
        )
        .catch(() =>
          notification.error({
            message: "Rolul nu poate fi modificat!",
          })
        )
        .finally(() => {
          setUpdateRole(undefined);
        });
    }
  }, [updateRole]);

  const usersColumns = useMemo<ColumnsType<RoleInterface>>(
    () => [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Permissions",
        dataIndex: "permissions",
        align: "right",
        width: 500,
        render: (_, role) => (
          <Select
            mode="multiple"
            value={role.permissions.map((i) => i.id)}
            onChange={(value) => setUpdateRole({ ...role, permissions: value })}
            loading={
              isPermissionsLoading ||
              (updateRole?.id === role.id && isUpdateLoading)
            }
            style={{ minWidth: 250 }}
          >
            {dataPermissions?.map((i) => (
              <Select.Option key={i.id} value={i.id}>
                {i.name}
              </Select.Option>
            ))}
          </Select>
        ),
      },
      {
        title: "Actions",
        align: "end",
        width: 100,
        render: (role) => (
          <Dropdown
            menu={{
              items: [
                {
                  label: "Delete",
                  key: "delete",
                  onClick: () => setDeleteRole(role),
                },
              ],
            }}
            trigger={["click"]}
            arrow
          >
            <MoreOutlined style={{ padding: 3 }} />
          </Dropdown>
        ),
      },
    ],
    [dataPermissions, isPermissionsLoading, updateRole, isUpdateLoading]
  );

  const onDeleteOkClick = () =>
    deleteRoleMutation(deleteRole?.id)
      .then(() =>
        notification.success({
          message: "Rolul a fost sters cu success",
        })
      )
      .catch(() => notification.error({ message: "Rolul nu poate fi sters!" }))
      .finally(() => setDeleteRole(undefined));

  return (
    <>
      <Flex justify="space-between" style={{ marginBottom: "1rem" }}>
        <Space wrap>
          <Input.Search
            placeholder="Cauta denumire"
            value={filters.name}
            onChange={(e) => onFiltersChange({ name: e.target.value })}
            allowClear
          />

          <Select
            loading={isLoading}
            mode="multiple"
            className="capitalize"
            placeholder="Select"
            value={filters.permissions}
            onChange={(permissions) => onFiltersChange({ permissions })}
            style={{ minWidth: 150 }}
            allowClear
          >
            {dataPermissions?.map((i) => (
              <Select.Option key={i.id} value={i.id} className="capitalize">
                {i.name}
              </Select.Option>
            ))}
          </Select>
        </Space>
        <Button
          type="primary"
          onClick={() => setShowRoleForm(true)}
          style={{ color: "white" }}
          icon={<PlusOutlined />}
        >
          Adauga rol
        </Button>
      </Flex>
      <Layout style={{ backgroundColor: palette.white }}>
        <Table
          columns={usersColumns}
          loading={isLoading}
          dataSource={data?.data}
          rowKey="id"
        />
      </Layout>

      {showRoleForm && <RoleForm onCancel={() => setShowRoleForm(false)} />}

      {deleteRole?.id && (
        <DeleteModal
          title="Sterge rol"
          onCancel={() => setDeleteRole(undefined)}
          onOk={onDeleteOkClick}
          okButtonProps={{ loading: isDeleteLoading }}
        >
          Esti sigur ca vrei sa stergi rolul {deleteRole?.name}
        </DeleteModal>
      )}
    </>
  );
};

import { ShorUserInterface } from "./users.types";
import { Country, County, MediaFile } from "./common.types";

export interface ListingInterface {
  id: number;
  isLiked: boolean;
  type: ListingsTypes;
  title: string;
  user: ShorUserInterface;
  country: Country;
  county: County;
  description: string;
  status: ListingsStatuses;
  likes: number;
  views: number;
  comments: number;
  createdAt: string;
  mediaFiles: MediaFile[];
  dates: string[];
}

export enum ListingsTypes {
  FREE = 1,
  PAID = 2,
}

export enum ListingsStatuses {
  PENDING = 1,
  ACTIVE = 2,
  INACTIVE = 3,
  BLOCK = 4,
}

export interface Comment {
  id: number;
  parentId: number;
  comment: string;
  user: ShorUserInterface;
  createdAt: string;
}

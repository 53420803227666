import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultApiConfig } from "./apiSettings";
import {
  CategoryInterface,
  City,
  Country,
  County,
  NotificationInterface,
  Permission,
  ServiceInterface,
  WithPagination,
} from "./common.types";
import { $Object } from "types/object";
import { defaultQueryStringify, today } from "libs";

export const commonApi = createApi({
  ...defaultApiConfig,
  reducerPath: "commonApi",
  tagTypes: [
    "Categories",
    "Services",
    "Terms",
    "Policy",
    "AllNotifications",
    "UnreadNotifications",
  ],
  endpoints: (builder) => ({
    getPermissions: builder.query<Permission[], {}>({
      query: () => "admin/permissions",
    }),
    getCountries: builder.query<Country[], {}>({
      query: () => "admin/countries",
    }),
    getCounties: builder.query<County[], {}>({
      query: (params) => `admin/counties?${defaultQueryStringify(params)}`,
    }),
    getCities: builder.query<City[], {}>({
      query: (params) => `admin/cities?${defaultQueryStringify(params)}`,
    }),
    getCategories: builder.query<CategoryInterface[], $Object>({
      query: (params) => `admin/categories?${defaultQueryStringify(params)}`,
      providesTags: ["Categories"],
    }),
    createCategory: builder.mutation({
      query: (body) => ({
        url: `admin/categories`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Categories"],
    }),
    updateCategory: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `admin/category/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Categories"],
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({ url: `admin/category/${id}`, method: "DELETE" }),
      invalidatesTags: ["Categories"],
    }),
    getServices: builder.query<ServiceInterface[], $Object>({
      query: (params) => `admin/services?${defaultQueryStringify(params)}`,
      providesTags: ["Services"],
    }),
    createService: builder.mutation({
      query: (body) => ({
        url: `admin/services`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Services"],
    }),
    updateService: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `admin/service/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Services", "Categories"],
    }),
    deleteService: builder.mutation({
      query: (id) => ({ url: `admin/service/${id}`, method: "DELETE" }),
      invalidatesTags: ["Services"],
    }),
    getTerms: builder.query<{ data: string }, undefined>({
      query: () => "public/terms-and-conditions",
      providesTags: ["Terms"],
    }),
    getPolicy: builder.query<{ data: string }, undefined>({
      query: () => "public/privacy-policy",
      providesTags: ["Policy"],
    }),
    updateTerms: builder.mutation({
      query: (body) => ({
        url: "admin/terms-and-conditions",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Terms"],
    }),
    updatePolicy: builder.mutation<{}, {}>({
      query: (body) => ({
        url: "admin/privacy-policy",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Policy"],
    }),
    getNotifications: builder.query<
      WithPagination<NotificationInterface>,
      $Object
    >({
      query: (params) =>
        `admin/admin-notifications?sort=created_at:desc&${defaultQueryStringify(
          params
        )}`,
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCacheData, responseData) => {
        currentCacheData.pagination = responseData.pagination;
        currentCacheData.data.push(...responseData.data);
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.page !== previousArg?.page;
      },
      providesTags: ["AllNotifications"],
    }),
    getUnreadNotifications: builder.query<
      WithPagination<NotificationInterface>,
      $Object
    >({
      query: (params) =>
        `admin/admin-notifications?filters[read_at][$null]=null&sort=created_at:desc&${defaultQueryStringify(
          params
        )}`,
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCacheData, responseData) => {
        currentCacheData.pagination = responseData.pagination;
        currentCacheData.data.push(...responseData.data);
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.page !== previousArg?.page,
      providesTags: ["UnreadNotifications"],
    }),
    setReadNotification: builder.mutation<{}, { id: string }>({
      query: ({ id }) => ({
        url: `/admin/read-notification/${id}`,
        method: "PATCH",
        body: { readAt: today.toString() },
      }),
    }),
    registerFCMToken: builder.mutation<{}, { token: string }>({
      query: (body) => ({
        url: "admin/register-fcm-token",
        method: "POST",
        body,
      }),
    }),
  }),
  refetchOnMountOrArgChange: false,
  refetchOnReconnect: false,
  keepUnusedDataFor: 600,
});

export const {
  useGetPermissionsQuery,
  useGetCountriesQuery,
  useGetCountiesQuery,
  useGetCitiesQuery,
  useGetCategoriesQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useGetServicesQuery,
  useCreateServiceMutation,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
  useGetTermsQuery,
  useUpdateTermsMutation,
  useGetPolicyQuery,
  useUpdatePolicyMutation,
  useGetNotificationsQuery,
  useGetUnreadNotificationsQuery,
  useRegisterFCMTokenMutation,
  useSetReadNotificationMutation,
} = commonApi;

import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import { UserInfo } from "./UserInfo";
import { TransactionsPage } from "../TransactionsPage";
import { ListingsPage } from "../ListingsPage";
import { UserStatisticPage } from "./UserStatisticPage";
import { ReportsPage } from "../ReportsPage";
import { UserChat } from "./UserChat";
import { useAppSelector } from "store/hooks";
import { getRouteByName } from "libs";

export const UserPage = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    isPostsRead,
    isReportsRead,
    isTransactionsRead,
    isUsersRead,
    isChatRead,
    isAll,
  } = useAppSelector((state) => state.loggedAdmin.parsedPermissions);

  useEffect(() => {
    if (!isUsersRead && !isAll) {
      navigate(getRouteByName("NotFoundPage"));
    }
  }, [isUsersRead, isAll]);

  const items = (() => {
    const defaultItems = [
      {
        label: "Informatie generala",
        key: "#information",
        children: <UserInfo />,
      },
      {
        label: "Statistica",
        key: "#statistics",
        children: <UserStatisticPage />,
      },
    ];

    if (isAll || isPostsRead) {
      defaultItems.push({
        label: "Anunturi",
        key: "#posts",
        children: <ListingsPage filterKey="user-listings" />,
      });
    }

    if (isAll || isTransactionsRead) {
      defaultItems.push({
        label: "Tranzactii",
        key: "#transactions",
        children: <TransactionsPage filterKey="user-transactions" />,
      });
    }

    if (isAll || isReportsRead) {
      defaultItems.push({
        label: "Raportari",
        key: "#reports",
        children: <ReportsPage filterKey="user-reports" />,
      });
    }
    if (isAll || isChatRead) {
      defaultItems.push({
        label: "Chat-uri",
        key: "#chats",
        children: <UserChat />,
      });
    }

    return defaultItems;
  })();

  return (
    <Tabs
      items={items}
      activeKey={hash ? hash : "#information"}
      onChange={(val) => navigate({ pathname, hash: val })}
      destroyInactiveTabPane
    >
      user page
    </Tabs>
  );
};

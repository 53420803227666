import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultApiConfig, UserStats } from "./index";
import { UserInterface } from "./users.types";
import { defaultQueryStringify } from "libs";

export const usersApi = createApi({
  ...defaultApiConfig,
  reducerPath: "usersApi",
  tagTypes: ["Users", "User"],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (params) =>
        `admin/users?${defaultQueryStringify(params)}&sort=created_at:desc`,
      providesTags: ["Users"],
    }),
    getUsersInfinite: builder.query({
      query: (params) =>
        `admin/users?${defaultQueryStringify(params)}&sort=created_at:desc`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCacheData, responseData, otherArgs) => {
        if (otherArgs.arg.search) {
          currentCacheData.pagination = responseData.pagination;
          currentCacheData.data = responseData.data;

          return;
        }

        currentCacheData.pagination = responseData.pagination;
        currentCacheData.data.push(...responseData.data);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getUser: builder.query<UserInterface, { id: number }>({
      query: ({ id }) => `admin/user/${id}`,
      providesTags: ["User"],
    }),
    updateUser: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `admin/user/${id}`,
        method: "PATCH",
        body,
      }),
    }),
    getUserStats: builder.query<UserStats, { id?: string }>({
      query: ({ id }) => `admin/user-stats/${id}`,
    }),
    deleteUser: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `admin/user/${id}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    updateUserStatus: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/admin/change-user-status/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUsersInfiniteQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useGetUserStatsQuery,
  useDeleteUserMutation,
  useUpdateUserStatusMutation,
} = usersApi;

import { Link, useParams } from "react-router-dom";
import { Card, Grid, Space, Spin, Statistic } from "antd";
import CountUp from "react-countup";
import { valueType } from "antd/lib/statistic/utils";
import { useHash } from "hooks";
import { useGetUserStatsQuery } from "api";
import { defaultQueryStringify, getRouteByName } from "libs";

const formatter = (value: valueType) => (
  <CountUp end={Number(value)} separator="," />
);

export const UserStatisticPage = () => {
  const { userId } = useParams();

  useHash("statistics");

  const { data, isLoading } = useGetUserStatsQuery({ id: userId });

  const breakpoints = Grid.useBreakpoint();

  return (
    <Spin spinning={isLoading}>
      <Space direction={breakpoints.xs ? "vertical" : "horizontal"}>
        <Card>
          <Link
            to={{
              pathname: getRouteByName("UserPage", { userId }),
              search: defaultQueryStringify({ "filters[type][$eq]": 1 }),
              hash: "#posts",
            }}
          >
            <Statistic
              title="Free posts"
              value={data?.freePosts}
              formatter={formatter}
            />
          </Link>
        </Card>
        <Card>
          <Link
            to={{
              pathname: getRouteByName("UserPage", { userId }),
              search: defaultQueryStringify({ "filters[type][$eq]": 2 }),
              hash: "#posts",
            }}
          >
            <Statistic
              title="Paid Posts"
              value={data?.paidPosts}
              formatter={formatter}
            />
          </Link>
        </Card>
        <Card>
          <Link
            to={{
              pathname: getRouteByName("UserPage", { userId }),
              hash: "#transactions",
            }}
          >
            <Statistic
              title="Transactions"
              value={data?.transactions}
              formatter={formatter}
            />
          </Link>
        </Card>
        <Card>
          <Link
            to={{
              pathname: getRouteByName("UserPage", { userId }),
              hash: "#reports",
            }}
          >
            <Statistic
              title="Reports"
              value={data?.reports}
              formatter={formatter}
            />
          </Link>
        </Card>
      </Space>
    </Spin>
  );
};

import { useEffect } from "react";
import {
  App,
  Button,
  Checkbox,
  Flex,
  Form,
  Input,
  Layout,
  Space,
  Typography,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { save } from "react-cookies";
import { getRouteByName, palette } from "libs";
import { useLogInMutation } from "api/auth";
import { setAuthTokens } from "store/loggedAdminSlice";
import { useAppDispatch } from "store/hooks";

import { ColorLogo } from "assets/svg";

export const LogInPage = () => {
  const [form] = Form.useForm();
  const { notification } = App.useApp();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [logIn, { data, isLoading }] = useLogInMutation();

  useEffect(() => {
    if (data?.token) {
      save("access-token", data?.token, { path: "/" });
      dispatch(setAuthTokens(data));
      notification.success({ message: "Te-ai logat cu success!" });
      navigate(
        getRouteByName(data.type === "superadmin" ? "AdminsPage" : "UsersPage")
      );
    }
  }, [data]);

  return (
    <Layout
      style={{
        padding: "3rem",
        flex: "inherit",
        borderRadius: ".5rem",
        backgroundColor: "white",
      }}
    >
      <Space direction="vertical" style={{ width: "20rem", gap: 0 }}>
        <Flex justify="center">
          <ColorLogo style={{ width: 150 }} />
        </Flex>

        <Form
          form={form}
          style={{ marginTop: "2rem" }}
          onFinish={(values) => logIn(values)}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Introdu email-ul",
              },
            ]}
          >
            <Input placeholder="Nume utilizator" size="large" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Introdu parola" }]}
          >
            <Input.Password placeholder="Parola" size="large" />
          </Form.Item>

          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "24px",
            }}
          >
            <Checkbox>Tine-ma logat</Checkbox>

            <Link to={getRouteByName("ResetPasswordPage")}>
              <Typography.Text style={{ color: palette.primaryColor }}>
                Am uitat parola
              </Typography.Text>
            </Link>
          </Space>

          <Button
            type="primary"
            style={{ width: "100%" }}
            htmlType="submit"
            size="large"
            loading={isLoading}
          >
            Logheaza-te
          </Button>
        </Form>
      </Space>
    </Layout>
  );
};

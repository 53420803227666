import { Flex, theme } from "antd";
import { Outlet } from "react-router-dom";

export const PublicLayout = () => {
  const { token } = theme.useToken();

  return (
    <Flex
      justify="center"
      align="center"
      style={{
        minHeight: "100vh",
        backgroundColor: `${token.colorPrimary}`,
      }}
    >
      <Outlet />
    </Flex>
  );
};

import { useEffect } from "react";
import { App, Form, Input, Modal } from "antd";
import { useCreateCategoryMutation } from "api";
import { getErrorMessage } from "components";

interface RoleFormInterface {
  onCancel: () => void;
}

export const CategoryForm = ({ onCancel }: RoleFormInterface) => {
  const [form] = Form.useForm();
  const { notification } = App.useApp();

  const [createRoleMutation, { isLoading, error, isSuccess }] =
    useCreateCategoryMutation();

  useEffect(() => {
    if (error) {
      notification.error({ message: getErrorMessage(error) });
    }
  }, [error, notification]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: "Categoria a fost modificata",
      });
      onCancel()
    }
  }, [isSuccess, notification, onCancel]);

  const onOkClick = () => createRoleMutation(form.getFieldsValue());

  return (
    <Modal
      title="Adauga rol"
      okText="Creeaza"
      onOk={onOkClick}
      okButtonProps={{ loading: isLoading }}
      onCancel={onCancel}
      cancelText="Inchide"
      open
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ width: "80%" }}
      >
        <Form.Item label="Nume" name="name">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

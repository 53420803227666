import { useState } from "react";
import { Layout } from "antd";
import {
  useGenerateValidationCodeMutation,
  useUpdateAdminPasswordMutation,
} from "api/auth";
import { SubmitEmailForm } from "./SubmitEmailForm";
import { SetPasswordForm } from "./SetPasswordForm";

export const ResetPasswordPage = () => {
  const [email, setEmail] = useState<string>();

  const [
    generateCodeMutation,
    {
      isLoading: isGenerateCodeLoading,
      isSuccess: isGenerateCodeSuccess,
      error: generateCodeError,
    },
  ] = useGenerateValidationCodeMutation();

  const [
    updatePasswordMutation,
    {
      isLoading: isUpdatePasswordLoading,
      isSuccess: isUpdatePasswordSuccess,
      error: updatePasswordError,
    },
  ] = useUpdateAdminPasswordMutation();

  const onSubmitEmailForm = (values: any) => {
    generateCodeMutation(values);
  };

  const onResendEmailCode = () => generateCodeMutation({ email });

  return (
    <Layout
      style={{
        padding: "3rem",
        flex: "inherit",
        borderRadius: ".5rem",
        backgroundColor: "white",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {!email ? (
          <SubmitEmailForm
            onSubmit={onSubmitEmailForm}
            isLoading={isGenerateCodeLoading}
            isSuccess={isGenerateCodeSuccess}
            onSuccess={(email: string) => setEmail(email)}
            error={generateCodeError}
          />
        ) : (
          <SetPasswordForm
            onFinish={updatePasswordMutation}
            onResendEmailCode={onResendEmailCode}
            isFinishLoading={isUpdatePasswordLoading}
            isSuccess={isUpdatePasswordSuccess}
            error={updatePasswordError}
          />
        )}
      </div>
    </Layout>
  );
};

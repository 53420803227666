import { theme } from "antd";
import { palette } from "libs";

export const useCustomToken = () => {
  const themeElements = theme.useToken();

  return {
    ...themeElements,
    token: { ...palette, ...themeElements.token },
  };
};

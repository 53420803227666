import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./types";

export const selectAppSettings = (state: RootState) => state.appSettings;
export const selectLoggedAdmin = (state: RootState) => state.loggedAdmin;

export const selectSideBarData = createSelector(
  [selectAppSettings, selectLoggedAdmin],
  (appSettings, loggedAdmin) => ({
    showSideBar: appSettings.showSideBar,
    parsedPermissions: loggedAdmin.parsedPermissions,
  })
);

import { configureStore } from "@reduxjs/toolkit";
import AppSettingsSlice from "./appSettingsSlice";
import {
  adminsApi,
  commonApi,
  listingsApi,
  reportsApi,
  rolesApi,
  transactionsApi,
  usersApi,
} from "api";
import LoggedAdmin from "./loggedAdminSlice";
import { chatApi } from "api/chat";
import { authApi } from "../api/auth";

export const store = configureStore({
  reducer: {
    loggedAdmin: LoggedAdmin,
    appSettings: AppSettingsSlice,
    [adminsApi.reducerPath]: adminsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [listingsApi.reducerPath]: listingsApi.reducer,
    [transactionsApi.reducerPath]: transactionsApi.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      adminsApi.middleware,
      usersApi.middleware,
      rolesApi.middleware,
      listingsApi.middleware,
      transactionsApi.middleware,
      commonApi.middleware,
      reportsApi.middleware,
      chatApi.middleware,
      authApi.middleware,
    ]),
});

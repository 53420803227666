import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  App,
  Button,
  Dropdown,
  Flex,
  Grid,
  Input,
  Layout,
  Select,
  Space,
  Table,
} from "antd";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { getRouteByName, palette } from "libs";
import { DeleteModal } from "components";
import {
  CategoryInterface,
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
  useGetServicesQuery,
} from "api";
import { useFilters } from "hooks";
import { CategoryForm } from "./CategoryForm";
import { useAppSelector } from "store/hooks";

interface RolesFiltersInterface {
  "filters[name][$contains]"?: string;
  "filters[services][id][$eq]"?: number[];
}

export const CategoriesPage = () => {
  const { notification } = App.useApp();
  const navigate = useNavigate();

  const [showCategoryForm, setShowCategoryForm] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState<CategoryInterface>();

  const { filters, onFiltersChange } = useFilters<RolesFiltersInterface>(
    {
      "filters[name][$contains]": undefined,
      "filters[services][id][$eq]": undefined,
    },
    { keyword: "categories" }
  );

  const { isCategoriesRead, isCategoriesCreate, isAll, isCategoriesDelete } =
    useAppSelector((state) => state.loggedAdmin.parsedPermissions);

  const { data, isLoading } = useGetCategoriesQuery(filters);

  const { data: dataServices, isLoading: isServicesLoading } =
    useGetServicesQuery({});

  const [deleteRoleMutation, { isLoading: isDeleteLoading }] =
    useDeleteCategoryMutation();

  const breakpoints = Grid.useBreakpoint();

  useEffect(() => {
    if (!isCategoriesRead && !isAll) {
      navigate(getRouteByName("NotFoundPage"));
    }
  }, [isCategoriesRead, isAll, navigate]);

  const usersColumns = useMemo<ColumnsType<CategoryInterface>>(
    () => [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Servicii",
        align: "right",
        render: (_, category) => (
          <Space>{category.services.map((i) => i.name).join(", ")}</Space>
        ),
      },
      {
        title: "Actions",
        align: "end",
        width: 100,
        render: (role) =>
          isAll || isCategoriesDelete ? (
            <Dropdown
              menu={{
                items: [
                  {
                    label: "Delete",
                    key: "delete",
                    onClick: () => setDeleteCategory(role),
                  },
                ],
              }}
              trigger={["click"]}
              arrow
            >
              <MoreOutlined style={{ padding: 3 }} />
            </Dropdown>
          ) : null,
      },
    ],
    [isCategoriesDelete, isAll]
  );

  const onDeleteOkClick = () =>
    deleteRoleMutation(deleteCategory?.id)
      .then(() =>
        notification.success({
          message: "Categoria a fost sters cu success",
        })
      )
      .catch(() =>
        notification.error({ message: "Categoria nu poate fi sters!" })
      )
      .finally(() => setDeleteCategory(undefined));

  return (
    <>
      <Flex
        justify="space-between"
        style={{
          marginBottom: "1rem",
          flexDirection: breakpoints.xs ? "column-reverse" : undefined,
        }}
        gap={8}
      >
        <Space wrap>
          <Input.Search
            placeholder="Cauta denumire"
            value={filters["filters[name][$contains]"]}
            onChange={(e) =>
              onFiltersChange({ "filters[name][$contains]": e.target.value })
            }
            allowClear
          />

          <Select
            loading={isServicesLoading}
            className="capitalize"
            placeholder="Servicii"
            value={filters["filters[services][id][$eq]"]}
            onChange={(services) =>
              onFiltersChange({ "filters[services][id][$eq]": services })
            }
            style={{ minWidth: 150 }}
            allowClear
          >
            {dataServices?.map((i) => (
              <Select.Option key={i.id} value={i.id} className="capitalize">
                {i.name}
              </Select.Option>
            ))}
          </Select>
        </Space>
        {(isAll || isCategoriesCreate) && (
          <Button
            type="primary"
            onClick={() => setShowCategoryForm(true)}
            style={{ color: "white" }}
            icon={<PlusOutlined />}
          >
            Adauga categorie
          </Button>
        )}
      </Flex>
      <Layout style={{ backgroundColor: palette.white }}>
        <Table
          columns={usersColumns}
          loading={isLoading}
          dataSource={data}
          rowKey="id"
          scroll={{ x: 500 }}
        />
      </Layout>

      {showCategoryForm && (
        <CategoryForm onCancel={() => setShowCategoryForm(false)} />
      )}

      {deleteCategory?.id && (
        <DeleteModal
          title="Sterge rol"
          onCancel={() => setDeleteCategory(undefined)}
          onOk={onDeleteOkClick}
          okButtonProps={{ loading: isDeleteLoading }}
        >
          Esti sigur ca vrei sa stergi categoria {deleteCategory?.name}
        </DeleteModal>
      )}
    </>
  );
};

import { MainLayout } from "./MainLayout";
import { SideBar } from "./SideBar";
import { superAdminRoutes } from "../libs";

export const SuperAdminLayout = () => {
  return (
    <MainLayout
      SideBar={<SideBar routes={superAdminRoutes} />}
      isSuperAdmin
    ></MainLayout>
  );
};

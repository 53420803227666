import { CategoryInterface, MediaFile, ServiceInterface } from "./common.types";

export interface UserInterface extends ShorUserInterface {
  email: string;
  type: UserTypes;
  status: UserStatuses;
  isVerified: boolean;
  lastActivity: string;
  profilePicture: string;
  legalAgreements: {
    termsAndConditions: boolean;
    privacyPolicy: boolean;
  };
  location: {
    latitude: number;
    longitude: number;
    country: { id: number; name: string };
    county: {
      id: number;
      name: string;
      code: string;
    };
    city: {
      id: number;
      name: string;
    };
    addressDetails: string
  };
  categories: CategoryInterface[];
  services: ServiceInterface[];
  notifications: {
    notifiedOnNewComments: boolean;
    notifiedOnNewPosts: boolean;
    notifiedOnNewMessages: boolean;
  };
  company: Company;
}

export interface ShorUserInterface {
  id: number;
  firstName: string;
  lastName: string;
  profilePicture: string;
}

export interface Company {
  id: number;
  name: string;
  description: string;
  logo: string;
  mediaFiles: MediaFile[];
}

export enum UserTypes {
  PHYSICAL = 1,
  JURIDICAL = 2,
}

export enum UserStatuses {
  PENDING = 1,
  ACTIVE = 2,
  INACTIVE = 3,
  BLOCKED = 4,
}

export interface UserStats {
  freePosts: number;
  paidPosts: number;
  transactions: number;
  reports: number;
}

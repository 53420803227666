import { useEffect, useState } from "react";
import {
  useDeleteUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdateUserStatusMutation,
} from "api/users";
import { useParams } from "react-router-dom";
import {
  App,
  Avatar,
  Button,
  Card,
  Flex,
  Form,
  GetProp,
  Grid,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Switch,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { Tag, UploadGallery } from "components";
import {
  useGetCategoriesQuery,
  useGetCitiesQuery,
  useGetCountiesQuery,
  useGetCountriesQuery,
  useGetServicesQuery,
  UserStatuses,
  UserTypes,
} from "api";
import { SmartSelect } from "components/SmartSelect";
import { enumKeys } from "types/object";
import { formErrorHandler, getBase64, normFile } from "libs";
import { useAppSelector } from "store/hooks";
import { SetPasswordModal } from "./SetPasswordModal";
import { Gallery } from "../../../components/Gallery";

export type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export const UserInfo = () => {
  const params = useParams();
  const [form] = Form.useForm();
  const formValues = form.getFieldsValue(true, ({ touched }) => touched);
  const { notification } = App.useApp();
  const { t } = useTranslation();

  const [isEditMode, setIsEditMode] = useState(false);
  const [showResetPassModal, setShowResetPassModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAdditionalAgreeModal, setShowAdditionalAgreeModal] =
    useState<UserStatuses>();
  const [reason, setReason] = useState<string>();
  const [userPhoto, setUserPhoto] = useState<string>();
  const [profilePhotoIsHovered, setProfilePictureHover] = useState<boolean>(false);
  const [companyPhotoIsHovered, setCompanyPictureHover] = useState<boolean>(false);
  const [companyLogo, setCompanyLogo] = useState<string>();
  const [companyPhotos, setCompanyPhotos] = useState<string[]>([]);

  const { isAll, isUsersEdit, isUsersDelete } = useAppSelector(
    (state) => state.loggedAdmin.parsedPermissions
  );

  const { data, isFetching, refetch } = useGetUserQuery({
    id: Number(params.userId),
  });

  const { data: categoriesData, isLoading: isCategoriesLoading } =
    useGetCategoriesQuery({});

  const { data: servicesData, isLoading: isServicesLoading } =
    useGetServicesQuery({});

  const [
    updateUserMutation,
    { isLoading, isSuccess: isSuccessUserUpdate, error: userUpdateError },
  ] = useUpdateUserMutation();

  const [
    updateUserStatusMutation,
    {
      isLoading: isUpdateUserStatusLoading,
      isSuccess: isUserUpdateStatusSuccess,
      error: updateStatusError,
    },
  ] = useUpdateUserStatusMutation();

  const [
    deleteUserMutation,
    { isLoading: isDeleteUserLoading, isSuccess, isError },
  ] = useDeleteUserMutation();

  const { data: dataCounties, isLoading: isCountiesLoading, refetch: refetchCounties } =
    useGetCountiesQuery({
      'filters[country_id][$eq]': formValues?.location?.country ? Number(formValues.location.country) : null
    }, {skip: !formValues?.location?.country || !isEditMode});

  const { data: dataCities, isLoading: isCitiesLoading, refetch: refetchCities } =
    useGetCitiesQuery({
      'filters[county][$eq]': formValues?.location?.county ? Number(formValues.location.county) : null
    }, {skip: !formValues?.location?.county && !isEditMode});

  const breakpoints = Grid.useBreakpoint();

  const userTypeValue = Form.useWatch("type", form);
  const selectedCategories = Form.useWatch("categories", form);

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      categories: data?.categories.map((i) => i.id),
      location: {
        country: data?.location.country.id,
        county: data?.location.county.id,
        city: data?.location.city.id,
        address_details: data?.location.addressDetails
      },
      profilePicture: [data?.profilePicture],
      company: {
        ...data?.company,
        logo: [data?.company.logo],
      },
    });
    setUserPhoto(data?.profilePicture);
    setCompanyLogo(data?.company.logo);
  }, [data, form]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: "Utilizatorul a fost sters cu success!",
      });
      setShowDeleteModal(false);
      setReason("");
    }
  }, [isSuccess, notification]);

  useEffect(() => {
    if (isSuccessUserUpdate) {
      setShowResetPassModal(false);
      setIsEditMode(false);
      refetch();
      notification.success({
        message: "Utilizatorul a fost modificat cu success!",
      });
    }
  }, [isSuccessUserUpdate, notification, refetch]);

  useEffect(() => {
    if (userUpdateError) {
      form.setFields(formErrorHandler((userUpdateError as any).data));
    }
  }, [userUpdateError, form]);

  useEffect(() => {
    if (isError) {
      notification.error({
        message: "Utilizatorul nu poate fi sters!",
      });
      setShowDeleteModal(false);
      setReason("");
    }
  }, [isError, notification]);

  useEffect(() => {
    if (isUserUpdateStatusSuccess) {
      notification.success({
        message: "Utilizatorul a fost modificat cu success!",
      });
      setShowAdditionalAgreeModal(undefined);
    }
  }, [isUserUpdateStatusSuccess, notification]);

  useEffect(() => {
    if (updateStatusError) {
      notification.success({
        message: "Utilizatorul nu poate fi modificat!",
      });
      setShowAdditionalAgreeModal(undefined);
    }
  }, [updateStatusError, notification]);

  const getBlocksWidth = () => {
    if (!breakpoints.md) {
      return "100%";
    }

    if (!breakpoints.lg) {
      return "49%";
    }

    return "100%";
  };

  const validateAndSubmit = () =>
    form
      .validateFields()
      .then(() => onFormSubmit())
      .catch((e) => console.log(e, "e"));
    
  const onFormSubmit = async () => {

    // console.log(formValues, 'values')
    //
    // return;

    // const foundLocation = dataCounties?.find(
    //   (i) => i.id === formValues.location.county
    // );
    const foundCity = dataCities?.find(
      (i) => i.id === formValues.location.city
    );
    updateUserMutation({
      id: data?.id,
      ...formValues,
      company: {
        ...formValues.company,
        mediaFiles: [
          ...(data?.company.mediaFiles.map((i) => i.id) || []),
          ...companyPhotos,
        ],
        logo: companyLogo
      },
      profilePicture: userPhoto,
      location: {
        ...formValues.location,
        city: foundCity?.name,
        latitude: foundCity?.latitude || 0,
        longitude: foundCity?.longitude || 0,
      }
    });
  };
  const onValuesChange = (changed: any) => {
    if (changed?.email) {
      form.setFieldValue('email', changed.email);
    }
    if (changed?.firstName) {
      form.setFieldValue('firstName', changed.firstName);
    }
    if (changed?.lastName) {
      form.setFieldValue('lastName', changed.lastName);
    }
    if (changed?.type) {
      form.setFieldValue('type', changed.type);
    }
    if (changed?.legalAgreements) {
      form.setFieldValue('legalAgreements', { ...formValues.legalAgreements, ...changed.legalAgreements })
    }
    if (changed?.location) {
      if (changed?.location?.country) {
        form.setFieldValue('location', { ...formValues.location, ...changed.location, county: null, city: null })
        refetchCounties();
      } else if (changed?.location?.county) {
        form.setFieldValue('location', { ...formValues.location, ...changed.location, city: null })
        refetchCities();
      } else if (changed?.location?.city) {
        form.setFieldValue('location', { ...formValues.location, ...changed?.location });
      } else {
        form.setFieldValue('location', { ...formValues.location, ...changed.location })
      }
    }
    if (changed?.notifications) {
      form.setFieldValue('notifications', { ...formValues.notifications, ...changed.notifications })
    }
    if (changed?.categories) {
      form.setFieldValue('categories', changed.categories);
    }
    if (changed?.company) {
      form.setFieldValue('company', { ...formValues.company, ...changed.company });
    }
  };
  const uploadButton = (
    <button 
      style={{ 
        border: 1, 
        borderRadius: 50,
        borderStyle: 'dotted',
        borderColor: '#c3c3c3',
        background: 'none',
        padding: 30,
        cursor: 'pointer'
      }} type="button"
    >
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );
  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space style={{ justifyContent: "flex-end", width: "100%" }}>
          {(isAll || isUsersEdit) && (
            <>
              {isEditMode && (
                <>
                  <Button onClick={() => setIsEditMode(false)}>Anuleaza</Button>
                  <Button
                    type="primary"
                    loading={isLoading}
                    onClick={validateAndSubmit}
                  >
                    Salveaza
                  </Button>
                </>
              )}

              {!isEditMode && (
                <Button type="primary" onClick={() => setIsEditMode(true)}>
                  Editeaza
                </Button>
              )}

              {data?.status === UserStatuses.PENDING && (
                <>
                  <Button
                    type="primary"
                    onClick={() =>
                      setShowAdditionalAgreeModal(UserStatuses.ACTIVE)
                    }
                  >
                    Aproba
                  </Button>
                  <Button
                    type="primary"
                    onClick={() =>
                      setShowAdditionalAgreeModal(UserStatuses.BLOCKED)
                    }
                  >
                    Respinge
                  </Button>
                </>
              )}

              <Button
                type="primary"
                onClick={() => setShowResetPassModal(true)}
              >
                Reseteaza parola
              </Button>
            </>
          )}

          {(isAll || isUsersDelete) && (
            <Button
              type="primary"
              danger
              onClick={() => setShowDeleteModal(true)}
            >
              Sterge
            </Button>
          )}
        </Space>

        <Form
          form={form}
          requiredMark={false}
          className="half-space-form"
          layout="vertical"
          onValuesChange={onValuesChange}
        >
          <Spin spinning={isFetching}>
            <Space
              align="start"
              style={{ width: "100%" }}
              styles={{
                item: {
                  width: getBlocksWidth(),
                },
              }}
              wrap={!breakpoints.lg}
            >
              <Card title="Utilizator">
                <Form.Item
                  name="profilePicture"
                  label={
                    <Typography.Text strong>Poza de profil:</Typography.Text>
                  }
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  {isEditMode ? (
                    <div 
                      onMouseEnter={() => setProfilePictureHover(true)}
                      onMouseLeave={() => setProfilePictureHover(false)}
                    >
                      <Upload
                        customRequest={(data) =>
                          getBase64(data.file as FileType, (url) => {
                            setUserPhoto(url);
                          })
                        }
                        showUploadList={false}
                      >
                        {!profilePhotoIsHovered
                          ? <Avatar src={userPhoto} size={100} /> 
                          : uploadButton
                        }
                      </Upload>
                    </div>
                  ) : (
                    <Avatar src={data?.profilePicture} size={100} />
                  )}
                </Form.Item>

                <Form.Item
                  name="firstName"
                  label={<Typography.Text strong>Prenume:</Typography.Text>}
                  required
                  rules={[{ required: true, message: <></> }]}
                >
                  {isEditMode ? <Input /> : data?.firstName}
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label={<Typography.Text strong>Nume:</Typography.Text>}
                  required
                  rules={[{ required: true, message: <></> }]}
                >
                  {isEditMode ? <Input /> : data?.lastName}
                </Form.Item>
                <Form.Item
                  name="email"
                  label={<Typography.Text strong>Email</Typography.Text>}
                  required
                >
                  {isEditMode ? <Input /> : data?.email}
                </Form.Item>
                <Form.Item
                  name="type"
                  label={<Typography.Text strong>Tip:</Typography.Text>}
                >
                  {isEditMode ? (
                    <Select
                      style={{ width: 150 }}
                      placeholder="Alege tip"
                      allowClear
                    >
                      {enumKeys(UserTypes).map((i) => (
                        <Select.Option key={i} value={UserTypes[i]}>
                          {i}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : data?.type ? (
                    t(UserTypes[data?.type])
                  ) : (
                    ""
                  )}
                </Form.Item>
                <Form.Item
                  label={<Typography.Text strong>Statut:</Typography.Text>}
                  required
                >
                  {data?.status ? t(UserStatuses[data?.status]) : ""}
                </Form.Item>

                <Form.Item
                  name="categories"
                  label={<Typography.Text strong>Categorii:</Typography.Text>}
                >
                  {isEditMode ? (
                    <Select mode="multiple" loading={isCategoriesLoading}>
                      {categoriesData?.map((i) => (
                        <Select.Option value={i.id} key={i.id}>
                          {i.name}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Space wrap>
                      {data?.categories.map((i) => (
                        <Tag key={i.name}>{i.name}</Tag>
                      ))}
                    </Space>
                  )}
                </Form.Item>

                <Form.Item
                  label={<Typography.Text strong>Verificat:</Typography.Text>}
                >
                  {data?.isVerified ? "Da" : "Nu"}
                </Form.Item>

                <Form.Item
                  label={
                    <Typography.Text strong>Ultima activitate:</Typography.Text>
                  }
                >
                  {data?.lastActivity}
                </Form.Item>
              </Card>

              <Space
                direction="vertical"
                styles={{ item: { width: "100%" } }}
                style={{ width: "100%" }}
              >
                {(data?.company.id ||
                  userTypeValue === UserTypes.JURIDICAL) && (
                  <Card title="Companie">
                    <Form.Item
                      name={["company", "logo"]}
                      label={
                        <Typography.Text strong>
                          Poza principala:
                        </Typography.Text>
                      }
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      {isEditMode ? (
                        <div 
                          onMouseEnter={() => setCompanyPictureHover(true)}
                          onMouseLeave={() => setCompanyPictureHover(false)}
                        >
                          <Upload
                            customRequest={(data) =>
                              getBase64(data.file as FileType, (url) => {
                                setCompanyLogo(url);
                              })
                            }
                            showUploadList={false}
                          >
                            {companyPhotoIsHovered 
                              ? uploadButton
                              : <Avatar src={companyLogo} size={100} />
                            }
                          </Upload>
                        </div>
                      ) : (
                        <Avatar src={companyLogo} size={100} />
                      )}
                    </Form.Item>

                    <Form.Item
                      name={["company", "name"]}
                      label={
                        <Typography.Text strong>Denumire:</Typography.Text>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Acest camp este obligatoriu",
                        },
                      ]}
                    >
                      {isEditMode ? <Input /> : data?.company.name}
                    </Form.Item>

                    <Form.Item
                      name={["company", "mediaFiles"]}
                      label={<Typography.Text strong>Poze:</Typography.Text>}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      {isEditMode ? (
                        <UploadGallery
                          customRequest={() => null}
                          onImagesChange={setCompanyPhotos}
                        />
                      ) : (
                        <Gallery mediaFiles={data?.company.mediaFiles} />
                      )}
                    </Form.Item>

                    <Form.Item
                      name={["company", "description"]}
                      label={
                        <Typography.Text strong>Descriere:</Typography.Text>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Acest camp este obligatoriu",
                        },
                      ]}
                      required
                    >
                      {isEditMode ? <Input.TextArea /> : "descriere"}
                    </Form.Item>

                    <Form.Item
                      name={["company", "services"]}
                      label={<Typography.Text strong>Servicii:</Typography.Text>}
                    >
                      {isEditMode ? (
                        <Select mode="multiple" loading={isServicesLoading}>
                          {servicesData
                            ?.filter((i) =>
                              selectedCategories.includes(i.category.id)
                            )
                            .map((i) => (
                              <Select.Option value={i.id} key={i.id}>
                                {i.name}
                              </Select.Option>
                            ))}
                        </Select>
                      ) : (
                        <Space wrap>
                          {data?.services.map((i) => (
                            <Tag key={i.name}>{i.name}</Tag>
                          ))}
                        </Space>
                      )}
                    </Form.Item>
                  </Card>
                )}

                <Card title="Locatie">
                  <Form.Item
                    name={["location", "country"]}
                    label={<Typography.Text strong>Tara:</Typography.Text>}
                    required
                    rules={[
                      { required: true, message: "Acest camp este obligatoriu" },
                    ]}
                  >
                    {isEditMode ? (
                      <SmartSelect
                        query={useGetCountriesQuery}
                        placeholder="Alege tara"
                        allowClear
                      />
                    ) : (
                      data?.location.country.name
                    )}
                  </Form.Item>
                  <Form.Item
                    name={["location", "county"]}
                    label={<Typography.Text strong>Judet:</Typography.Text>}
                    required
                    rules={[
                      { required: true, message: "Acest camp este obligatoriu" },
                    ]}
                  >
                    {isEditMode ? (
                      <Select
                        loading={isCountiesLoading}
                        className="capitalize"
                        placeholder="Alege judetul"
                        allowClear
                        disabled={!formValues?.location?.country}
                      >
                        {dataCounties?.map((i) => (
                          <Select.Option
                            key={`county-${i.id}`}
                            value={i.id}
                            className="capitalize"
                          >
                            {i.name}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      data?.location.county.name
                    )}
                  </Form.Item>
                  <Form.Item
                    name={["location", "city"]}
                    label={<Typography.Text strong>Oras:</Typography.Text>}
                    required
                    rules={[
                      { required: true, message: "Acest camp este obligatoriu" },
                    ]}
                  >
                    {isEditMode ? (
                      <Select
                        loading={isCitiesLoading}
                        className="capitalize"
                        placeholder="Alege orasul"
                        allowClear
                        disabled={!formValues?.location?.county}
                      >
                        {dataCities?.map((i) => (
                          <Select.Option
                            key={`city-${i.id}`}
                            value={i.id}
                            className="capitalize"
                          >
                            {i.name}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      data?.location.city.name
                    )}
                  </Form.Item>
                  <Form.Item
                    name={["location", "address_details"]}
                    label={
                      <Typography.Text strong>Adresa:</Typography.Text>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Acest camp este obligatoriu",
                      },
                    ]}
                  >
                    {isEditMode ? <Input value={data?.location.addressDetails} /> : data?.location.addressDetails}
                  </Form.Item>
                </Card>
              </Space>

              <Flex gap="8px" vertical>
                <Card title="Legal Agreement">
                  <Form.Item
                    name={["legalAgreements", "termsAndConditions"]}
                    label={
                      <Typography.Text strong>
                        Termenii si conditiile:
                      </Typography.Text>
                    }
                  >
                    {isEditMode ? (
                      <Switch defaultChecked={formValues.legalAgreements.termsAndConditions} />
                    ) : data?.legalAgreements.termsAndConditions ? (
                      "Da"
                    ) : (
                      "Nu"
                    )}
                  </Form.Item>
                  <Form.Item
                    name={["legalAgreements", "privacyPolicy"]}
                    label={
                      <Typography.Text strong>
                        Politica de confidentialitate:
                      </Typography.Text>
                    }
                  >
                    {isEditMode ? (
                      <Switch defaultChecked={formValues.legalAgreements.privacyPolicy} />
                    ) : data?.legalAgreements.privacyPolicy ? (
                      "Da"
                    ) : (
                      "Nu"
                    )}
                  </Form.Item>
                </Card>

                <Card title="Notificari">
                  <Form.Item
                    name={["notifications", "notifiedOnNewComments"]}
                    label={
                      <Typography.Text strong>
                        La comentarii noi:
                      </Typography.Text>
                    }
                  >
                    {isEditMode ? (
                      <Switch defaultChecked={formValues?.notifications.notifiedOnNewComments} />
                    ) : data?.notifications.notifiedOnNewComments ? (
                      "Da"
                    ) : (
                      "Nu"
                    )}
                  </Form.Item>
                  <Form.Item
                    name={["notifications", "notifiedOnNewPosts"]}
                    label={
                      <Typography.Text strong>La post-uri noi:</Typography.Text>
                    }
                  >
                    {isEditMode ? (
                      <Switch defaultChecked={formValues?.notifications.notifiedOnNewPosts} />
                    ) : data?.notifications.notifiedOnNewPosts ? (
                      "Da"
                    ) : (
                      "Nu"
                    )}
                  </Form.Item>
                  <Form.Item
                    name={["notifications", "notifiedOnNewMessages"]}
                    label={
                      <Typography.Text strong>La mesaje noi:</Typography.Text>
                    }
                  >
                    {isEditMode ? (
                      <Switch defaultChecked={formValues?.notifications.notifiedOnNewMessages} />
                    ) : data?.notifications.notifiedOnNewMessages ? (
                      "Da"
                    ) : (
                      "Nu"
                    )}
                  </Form.Item>
                </Card>
              </Flex>
            </Space>
          </Spin>
        </Form>
      </Space>

      {showResetPassModal && (
        <SetPasswordModal
          onCancel={() => setShowResetPassModal(false)}
          mutation={updateUserMutation}
          isLoading={isLoading}
        />
      )}

      {showAdditionalAgreeModal && (
        <Modal
          open
          title="Modificarea utilizatorul"
          okText="Modifica"
          cancelText="Inchide"
          styles={{ body: { margin: "2rem 0" } }}
          onCancel={() => setShowAdditionalAgreeModal(undefined)}
          onOk={() =>
            updateUserStatusMutation({
              id: data?.id,
              status: showAdditionalAgreeModal,
              reason,
            })
          }
          okButtonProps={{
            loading: isUpdateUserStatusLoading,
            disabled:
              UserStatuses.BLOCKED === showAdditionalAgreeModal && !reason,
          }}
        >
          <Typography.Text>
            Esti sigur ca vrei sa schimbi statul utilizatorului in "
            {UserStatuses[showAdditionalAgreeModal]}"?
          </Typography.Text>
          {UserStatuses.BLOCKED === showAdditionalAgreeModal && (
            <Form.Item label="Cauza:" style={{ marginTop: "1rem" }}>
              <Input
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Form.Item>
          )}
        </Modal>
      )}

      {showDeleteModal && (
        <Modal
          open
          title="Modificarea postarea"
          okText="Modifica"
          cancelText="Inchide"
          styles={{ body: { margin: "2rem 0" } }}
          onCancel={() => setShowDeleteModal(false)}
          onOk={() =>
            deleteUserMutation({
              id: data?.id,
              reason,
            })
          }
          okButtonProps={{
            loading: isDeleteUserLoading,
            disabled: !reason,
          }}
        >
          <Typography.Text>
            Esti sigur ca vrei sa stergi acest utilizator?
          </Typography.Text>

          <Form.Item label="Cauza:" style={{ marginTop: "1rem" }}>
            <Input value={reason} onChange={(e) => setReason(e.target.value)} />
          </Form.Item>
        </Modal>
      )}
    </>
  );
};

import { useMemo, useState } from "react";
import { Avatar, Layout, List, Space, theme } from "antd";
import { Link, useParams } from "react-router-dom";
import { chatDateTime, format, getRouteByName } from "libs";
import { RoomInterface } from "api/chat.types";
import { RoomMessages } from "./RoomMessages";
import { useAppSelector } from "../../../store/hooks";

interface RoomItemInterface {
  room: RoomInterface;
}

export const RoomItem = ({ room }: RoomItemInterface) => {
  const { userId } = useParams();
  const { token } = theme.useToken();

  const [showMessages, setShowMessages] = useState(false);

  const { isAll, isPostsRead } = useAppSelector(
    (state) => state.loggedAdmin.parsedPermissions
  );

  const actions = useMemo(() => {
    const actionsArray = [];

    if (room?.totalMessages > 1 && !showMessages) {
      actionsArray.push(
        <span
          style={{ color: token.colorPrimary, cursor: "pointer" }}
          onClick={() => setShowMessages(true)}
        >
          Vezi mesajele anterioare
        </span>
      );
    }

    return actionsArray;
  }, [room, showMessages]);

  return (
    <List.Item
      actions={actions}
      style={{
        flexDirection: "column",
        alignItems: "inherit",
      }}
    >
      <div style={{ marginBottom: "1rem" }}>
        {isAll || isPostsRead ? (
          <Link
            to={getRouteByName("ListingPage", { listingId: room?.post.id })}
          >
            {room?.post.title}
          </Link>
        ) : (
          room?.post.title
        )}
      </div>

      {showMessages ? (
        <Layout style={{ borderRadius: 8, padding: "1rem 0 0 1rem" }}>
          <RoomMessages roomId={room?.chatRoom} />
        </Layout>
      ) : (
        <List.Item.Meta
          avatar={
            <Avatar
              src={room?.user.profilePicture}
              style={{ position: "relative", zIndex: 1 }}
            />
          }
          title={
            <Space>
              <Link
                to={getRouteByName("UserPage", {
                  userId: room?.messages[0].user?.id,
                })}
                style={{ fontWeight: 400 }}
              >
                {room?.messages[0].user?.firstName}
                {room?.messages[0].user?.lastName}
              </Link>
            </Space>
          }
          description={
            <div style={{ width: "fit-content" }}>
              <div
                style={{
                  background:
                    Number(userId) === room?.messages[0].user?.id
                      ? `${token.colorPrimary}30`
                      : `#f5f5f5`,
                  borderRadius: 8,
                  padding: "4px 8px",
                }}
              >
                {room?.messages[0].message}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  color: "grey",
                  fontWeight: 300,
                }}
              >
                {format(room?.messages[0].created_at, chatDateTime)}
              </div>
            </div>
          }
          style={{ position: "relative", marginBottom: ".5rem" }}
        />
      )}
    </List.Item>
  );
};

import { type Dispatch, type SetStateAction, useState } from "react";
import { useThrottledCallback } from "./useThrottleCallback";

export function useThrottledState<S>(
  initialState: S | (() => S),
  delay: number,
  noTrailing = false
): [S, Dispatch<SetStateAction<S>>] {
  const [state, setState] = useState(initialState);

  return [state, useThrottledCallback(setState, [], delay, noTrailing)];
}

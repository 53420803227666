import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultApiConfig } from "./apiSettings";
import { WithPagination } from "./common.types";
import { ReportInterface } from "./reports.types";
import { $Object } from "types/object";
import { defaultQueryStringify } from "../libs";

export const reportsApi = createApi({
  ...defaultApiConfig,
  reducerPath: "reportsApi",
  tagTypes: ["Reports"],
  endpoints: (builder) => ({
    getReports: builder.query<WithPagination<ReportInterface>, $Object>({
      query: (params) => ({
        url: `admin/reports?${defaultQueryStringify(params)}`,
      }),
      providesTags: ["Reports"],
    }),
    updateReport: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `admin/reports/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Reports"],
    }),
  }),
});

export const { useGetReportsQuery, useUpdateReportMutation } = reportsApi;

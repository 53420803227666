import { App, Button, Dropdown, Flex, Input, Layout, Space, Table } from "antd";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { useMemo, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { useDeleteAdminMutation, useGetAdminsQuery } from "api/admins";
import { palette } from "libs";
import { DeleteModal } from "components";
import { AdminInterface } from "api";
import { AdminForm, RolesSelect } from "./components";
import { useFilters } from "hooks";

interface AdminFiltersInterface {
  page: number;
  search?: string;
  "filters[email][$contains]"?: string;
  "filters[role][$contains]"?: string;
}

export const AdminsPage = () => {
  const { notification } = App.useApp();

  const [showAdminForm, setShowAdminForm] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState<AdminInterface>();
  const [deleteAdmin, setDeleteAdmin] = useState<AdminInterface>();

  const { filters, onFiltersChange } = useFilters<AdminFiltersInterface>(
    {
      page: 1,
      search: undefined,
      "filters[email][$contains]": undefined,
      "filters[role][$contains]": undefined,
    },
    { keyword: "admins" }
  );

  const { data, isLoading, isFetching } = useGetAdminsQuery(filters);

  const [deleteAdminMutation, { isLoading: isDeletedAdminLoading }] =
    useDeleteAdminMutation();

  const usersColumns = useMemo<ColumnsType<AdminInterface>>(
    () => [
      {
        title: "Name",
        render: (admin) => `${admin.firstName} ${admin.lastName}`,
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Role",
        dataIndex: ["roles", "name"],
        width: 200,
        className: "capitalize",
      },
      {
        title: "Actions",
        align: "end",
        render: (admin) => (
          <Dropdown
            menu={{
              items: [
                {
                  label: "Edit",
                  key: "edit",
                  onClick: () => {
                    setSelectedAdmin(admin);
                    setShowAdminForm(true);
                  },
                },
                {
                  label: "Delete",
                  key: "delete",
                  onClick: () => setDeleteAdmin(admin),
                },
              ],
            }}
            trigger={["click"]}
            arrow
          >
            <MoreOutlined />
          </Dropdown>
        ),
      },
    ],
    []
  );

  const onDeleteOkClick = () =>
    deleteAdminMutation(deleteAdmin?.id)
      .then(() =>
        notification.success({
          message: "Adminul a fost sters cu success",
        })
      )
      .catch(() =>
        notification.error({ message: "Adminul nu poate fi sters!" })
      )
      .finally(() => setDeleteAdmin(undefined));

  return (
    <>
      <Flex justify="space-between" style={{ marginBottom: "1rem" }}>
        <Space wrap>
          <Input.Search
            placeholder="Cauta"
            value={filters.search}
            onChange={(val) =>
              onFiltersChange({
                search: val.target.value,
              })
            }
            allowClear
          />

          <RolesSelect
            style={{ width: 150 }}
            placeholder="Select"
            allowClear
            value={filters["filters[role][$contains]"]}
            onChange={(val) =>
              onFiltersChange({ "filters[role][$contains]": val })
            }
          />
        </Space>
        <Button
          type="primary"
          onClick={() => setShowAdminForm(true)}
          style={{ color: "white" }}
          icon={<PlusOutlined />}
        >
          Add admin
        </Button>
      </Flex>
      <Layout style={{ backgroundColor: palette.white }}>
        <Table
          columns={usersColumns}
          loading={isLoading || isFetching}
          dataSource={data?.data}
          rowKey="id"
          pagination={{
            total: data?.pagination.total,
            current: data?.pagination.currentPage,
            pageSize: data?.pagination.itemsPerPage,
            onChange: (page) => onFiltersChange({ page }),
          }}
        />
      </Layout>

      {showAdminForm && (
        <AdminForm
          admin={selectedAdmin}
          onCancel={() => {
            setShowAdminForm(false);
            setSelectedAdmin(undefined);
          }}
        />
      )}

      {deleteAdmin?.id && (
        <DeleteModal
          onOk={onDeleteOkClick}
          okButtonProps={{ loading: isDeletedAdminLoading }}
          onCancel={() => setDeleteAdmin(undefined)}
          title={`Sterge admin ${deleteAdmin?.firstName} ${deleteAdmin?.lastName}`}
        >
          Esti sigur ca vrei sa stergi adminul {deleteAdmin?.firstName}{" "}
          {deleteAdmin?.lastName}?
        </DeleteModal>
      )}
    </>
  );
};

import { routes } from "./routes";
import { default as queryString } from "query-string";

export const getRouteByName = (
  name: string,
  params: { [key: string]: any } = {},
  hash?: string
) => {
  const route = routes.find((route) => route.name === name);

  if (route) {
    let { path } = route;

    Object.keys(params).map(
      (key) => (path = path.replace(`:${key}`, params[key]))
    );

    return params.query
      ? `${path}${queryString.stringify(params.query)}${hash}`
      : path;
  }

  return "";
};

import { Select, SelectProps } from "antd";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { QueryDefinition } from "@reduxjs/toolkit/query";

interface SmartSelectInterface extends SelectProps {
  query: UseQuery<QueryDefinition<any, any, string, any[], string>>;
}

export const SmartSelect = ({ query, ...props }: SmartSelectInterface) => {
  const { data, isLoading } = query({});

  return (
    <Select loading={isLoading} className="capitalize" {...props}>
      {data?.map((i) => (
        <Select.Option key={i.id} value={i.id} className="capitalize">
          {i.name}
        </Select.Option>
      ))}
    </Select>
  );
};

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { load } from "react-cookies";
import { getRouteByName } from "libs";

export const IndexPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectToRightRoute = () => {
      const savedToken = load("access-token");

      if (savedToken) {
        navigate(getRouteByName("UsersPage"));
        return;
      }

      navigate(getRouteByName("LogInPage"));
    };

    redirectToRightRoute();
  }, []);

  return <></>;
};
